import React from 'react';
import styles from './BagEligibility.module.css';
import globalStyles from '../global.module.css';

import { BAG_STATUS, DETAILS_STATES, LOADING_LOCS, ISSUE_CATEGORIES } from '../util';

import externalDamage from './images/externalDamage.png';
import itemDamage from './images/itemDamage.png';
import delayExpenses from './images/delayExpenses.png';
import info from './images/info.svg';
import chevGrey from './images/chevGrey.svg';
import chevWhite from './images/chevWhite.svg';
import suitcase from './images/suitcase.png';
import luggage from './images/luggage.png';
import cancel from './images/close.png';
import arrow from './images/arrow.svg';

class BagEligibility extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bag1Selected: true,
        }

        this.toggleBags = this.toggleBags.bind(this);
    }

    toggleBags() {
        this.setState({bag1Selected: !this.state.bag1Selected});
    }

    render() {
        if (this.props.data.category != ISSUE_CATEGORIES.BAG_DAMAGE) {
            return (<div />);
        }
        if (this.props.state < DETAILS_STATES.BAG_ELIGIBILITY && !this.props.visited[DETAILS_STATES.BAG_ELIGIBILITY]) {
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterPreview}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitlePreview}`}>Eligibility</div>
                    {
                        LOADING_LOCS.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                    }
                </div>
            )
        }
        if (this.props.state == DETAILS_STATES.BAG_ELIGIBILITY) {
            let bags = [];
            if (this.props.data.bags[0]) bags.push(this.props.baseData.bags[0]);
            if (this.props.data.bags[1]) bags.push(this.props.baseData.bags[1]);
            let damageDisplay = BAG_STATUS.DELIVERED;
            if (bags.length == 1 || (bags.length == 2 && this.state.bag1Selected)) {
                damageDisplay = bags[0].status;
            }
            else {
                damageDisplay = bags[1].status;
            }
            let totalAccepted = 0;
            let totalRejected = 0;
            let totalDisputed = 0;
            let statusColors = {
                "Accepted": "#84C631",
                "Disputed": "#EEE741",
                "Rejected": "rgba(255, 35, 35, .7)"
            };
            for (let i = 0; i < this.props.data.bagClaims.length; i++) {
                totalAccepted += this.props.data.bagClaims[i]["amount"];
            }
            return (
                <div>
                    <div className={globalStyles.stepOuter} style={{height: `${this.props.data.bagClaims.length > 0 ? 845 : 419}px`, marginBottom: "-23px"}}>
                        <div className={globalStyles.stepTitle}>Eligibility</div>
                        {
                            bags.length == 2 ? 
                            <div id={styles.receiptToggleOuter} onClick={this.toggleBags}>
                                <div id={styles.receiptToggle} style={{left: `${this.state.bag1Selected ? 4 : 448}px`}} onClick={(e) => e.stopPropagation()}/>
                                <div className={styles.receiptToggleText} style={{left: "185px"}} onClick={(e) => {if (this.state.bag1Selected) {e.stopPropagation()}}}>Bag ID: {this.props.baseData.bags[0].id}</div>
                                <div className={styles.receiptToggleText} style={{left: "642px"}} onClick={(e) => {if (!this.state.bag1Selected) {e.stopPropagation()}}}>Bag ID: {this.props.baseData.bags[1].id}</div>
                                <img src={suitcase} className={styles.receiptToggleImg} style={{left: "163px"}} onClick={(e) => {if (this.state.bag1Selected) {e.stopPropagation()}}}/>
                                <img src={suitcase} className={styles.receiptToggleImg} style={{left: "617px"}} onClick={(e) => {if (!this.state.bag1Selected) {e.stopPropagation()}}}/>
                            </div>
                            :
                            <div />
                        }
                        <div className={styles.accomodationType} style={{left: `${damageDisplay == BAG_STATUS.LATE ? 29 : 180}px`, display: `${damageDisplay == BAG_STATUS.PENDING ? "None" : "Inline"}`, top: `${bags.length == 2 ? 127 : 100}px`}}>
                            <img src={externalDamage} className={styles.accomodationImg} />
                            <img src={info} className={styles.infoImg} />
                            <div className={styles.accomodationText} style={{top: "94px"}}>External Baggage<br/>Damage</div>
                            <div className={styles.claim} onClick={() => this.props.triggerPopup("External Bag Damage")}>
                                <div className={styles.claimText}>Claim</div>
                            </div>
                        </div>
                        <div className={styles.accomodationType} style={{left: `${damageDisplay == BAG_STATUS.LATE ? 643 : 180}px`, display: `${damageDisplay == BAG_STATUS.DELIVERED ? "None" : "Inline"}`, top: `${bags.length == 2 ? 127 : 100}px`}}>
                            <img src={delayExpenses} className={styles.accomodationImg} />
                            <img src={info} className={styles.infoImg} />
                            <div className={styles.accomodationText}>Delay Expenses</div>
                            <div className={styles.claim} onClick={() => this.props.triggerPopup("Delay Expenses")}>
                                <div className={styles.claimText}>Claim</div>
                            </div>
                        </div>
                        <div className={styles.accomodationType} style={{left: `${damageDisplay == BAG_STATUS.LATE ? 335 : 486}px`, display: `${damageDisplay == BAG_STATUS.PENDING ? "None" : "Inline"}`, top: `${bags.length == 2 ? 127 : 100}px`}}>
                            <img src={itemDamage} className={styles.accomodationImg} style={{width: "60px", left: "110px", top: "30px"}} />
                            <img src={info} className={styles.infoImg} />
                            <div className={styles.accomodationText}>Item Damage</div>
                            <div className={styles.claim} onClick={() => this.props.triggerPopup("Item Damage")}>
                                <div className={styles.claimText}>Claim</div>
                            </div>
                        </div>
                        <div className={styles.accomodationType} style={{left: `486px`, display: `${damageDisplay == BAG_STATUS.PENDING ? "Inline" : "None"}`, top: `${bags.length == 2 ? 127 : 100}px`}}>
                            <img src={luggage} className={styles.accomodationImg} style={{width: "60px", left: "110px", top: "30px"}} />
                            <img src={info} className={styles.infoImg} />
                            <div className={styles.accomodationText}>Claim Lost Items</div>
                            <div className={styles.claim} onClick={() => this.props.triggerPopup("Lost Items")}>
                                <div className={styles.claimText}>Claim</div>
                            </div>
                        </div>
                        {
                            this.props.data.bagClaims.length > 0 ? 
                            <div id={styles.claimsOuter}>
                                <div id={styles.yourClaims}>Your Claims</div>
                                <div className={styles.totalsLeft} style={{left: "328px", backgroundColor: "#84C631"}}>Accepted</div>
                                <div className={styles.totalsRight} style={{left: "413px", backgroundColor: "rgba(132, 198, 49, .3)"}}>${totalAccepted.toFixed(2)}</div>
                                <div className={styles.totalsLeft} style={{left: "510px", backgroundColor: "#EEE741"}}>Disputed</div>
                                <div className={styles.totalsRight} style={{left: "595px", backgroundColor: "rgba(238, 231, 65, .3)"}}>${totalDisputed.toFixed(2)}</div>
                                <div className={styles.totalsLeft} style={{left: "692px", backgroundColor: "rgba(255, 35, 35, .7"}}>Rejected</div>
                                <div className={styles.totalsRight} style={{left: "777px", backgroundColor: "rgba(255, 128, 98, .3)"}}>${totalRejected.toFixed(2)}</div>
                                <div className={styles.claimsHeaders} style={{left: "28px"}}>Status</div>
                                <div className={styles.claimsHeaders} style={{left: "138px"}}>Amount</div>
                                <div className={styles.claimsHeaders} style={{left: "230px"}}>Name</div>
                                <div id={styles.topLine}>
                                    {
                                        this.props.data.bagClaims.map((claim, index) => (
                                            <div className={styles.claimEntry} style={{top: `${index * 47}px`}} key={index}>
                                                <div className={styles.status} style={{backgroundColor: statusColors[claim["status"]]}}>{claim["status"]}</div>
                                                <div className={styles.claimAmount}>${claim["amount"].toFixed(2)}</div>
                                                <div className={styles.claimName}>{claim["date"]} - {claim["vendor"]}</div>
                                                <div className={styles.cancelClaim} onClick={() => this.props.removeClaim(index)}>
                                                    <img src={cancel} className={styles.cancelClaimImg} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            :
                            <div id={styles.moreInfo}><u>Not what you were looking for?</u></div>
                        }
                    </div>
                    {
                        this.props.data.bagClaims.length > 0 ? 
                        <div className={globalStyles.continue} onClick={() => this.props.updateState(DETAILS_STATES.CONFIRM)}>
                            <div id={styles.selectText} style={{color: "white", left: "65px"}}>Confirm Claims</div>
                            <img src={chevWhite} id={styles.selectImg} />
                        </div>
                        :
                        <div className={globalStyles.continueDisabled}>
                            <div id={styles.selectText} style={{color: "#A7A7A7"}}>Add Claims To Continue</div>
                            <img src={chevGrey} id={styles.selectImg} />
                        </div>
                    }
                </div>
            )
        }
        return (
            <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
                <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitleVisited}`}>Eligibility</div>
                <div className={globalStyles.changeTrip} onClick={() => this.props.updateState(DETAILS_STATES.BAG_ELIGIBILITY)}>
                    <div className={globalStyles.changeTripText}>Change Selection</div>
                    <img src={arrow} className={globalStyles.changeTripImg} />
                </div>
            </div>
        );
    }
}
export default BagEligibility;