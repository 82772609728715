import React from 'react';
import styles from './ResolutionForm.module.css';
import globalStyles from '../../global.module.css';
import { CLAIM_STATES, CLAIM_STATUS, getErrorSubtitle, getErrorTitle } from '../../util';

import disputeLogo from '../images/disputeLogo.svg';
import close from '../images/close.png';
import chevBlue from '../images/chevBlue.svg';
import chevGrey from '../images/chevGrey.svg';
import checkmark from '../images/checkmark_green.svg';

class ErrorControls extends React.Component {
    render() {
        let height = (this.props.count + 2) * 21;
        return (
            <div id={styles.errorControls} style={{height: `${height}px`, top: `${320 - height / 2}px`}}>
                {
                    this.props.index == 0 ? 
                    <img src={chevGrey} id={styles.navUp} />
                    :
                    <img src={chevBlue} id={styles.navUp} onClick={this.props.navUp} style={{transform: "rotate(270deg)", cursor: "pointer"}} />
                }
                {
                    Array(this.props.count).fill(0).map((_, index) => 
                        <div className={styles.errorCircle} key={index}>
                            <div id={styles.selectedCircle} style={{display: this.props.index == index ? "block" : "none"}} />
                        </div>
                    )
                }
                {
                    this.props.index == this.props.count - 1 ? 
                    <img src={chevGrey} id={styles.navDown} />
                    :
                    <img src={chevBlue} id={styles.navDown} onClick={this.props.navDown} style={{transform: "rotate(90deg)", cursor: "pointer"}} />
                }
            </div>
        );
    }
}

class ResolutionForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorIndex: 0
        }
    }

    render() {
        if (this.props.state != CLAIM_STATES.RESOLUTION) {
            return (<div />);
        }
        else if (this.props.isDispute) {
            return (
                <div>
                <div className={globalStyles.popupHeader} style={{backgroundColor: "rgba(238, 231, 65, 0.3)"}}>
                    <div className={globalStyles.popupHeaderImgOuter}>
                        <img src={disputeLogo} className={globalStyles.popupHeaderImg} />
                    </div>
                    <div className={globalStyles.popupHeaderTitle}>Dispute</div>
                    <div className={globalStyles.closeLarge} onClick={this.props.close}>
                        <img src={close} className={globalStyles.closeLargeImg} />
                    </div>
                </div>
                <img src={checkmark} id={styles.checkmarkDispute} />
                <div id={styles.disputeTitle}>Dispute Submitted</div>
                <div className={styles.button} style={{left: "337px", top: "567px", width: "226px"}} onClick={() => this.props.submit(CLAIM_STATUS.DISPUTED)}>
                    <div className={styles.buttonTextLeft}>Acknowledge</div>
                    <img src={chevBlue} className={styles.buttonImgRight} />
                </div>
                </div>
            )
        }
        else if (this.props.errors.length > 0) {
            return (
                <div id={styles.background}>
                    <div className={globalStyles.closeLarge} onClick={this.props.close}>
                        <img src={close} className={globalStyles.closeLargeImg} />
                    </div>
                    <div id={styles.rejectedTitle}>Reimbursement Not Approved</div>
                    <div className={styles.errorTile}>
                        <div className={styles.errorTileTitle}>{getErrorTitle(this.props.errors[this.state.errorIndex])}</div>
                        <div className={styles.errorTileSubtitle}>{getErrorSubtitle(this.props.errors[this.state.errorIndex])}</div>
                    </div>
                    <ErrorControls count={this.props.errors.length} index={this.state.errorIndex} navUp={() => this.setState({errorIndex: this.state.errorIndex - 1})} navDown={() => this.setState({errorIndex: this.state.errorIndex + 1})} />
                    <div className={styles.button} style={{left: "301px", top: "493px"}} onClick={this.props.refile}>
                        <div className={styles.buttonTextRight}>Refile Claim</div>
                        <img src={chevBlue} className={styles.buttonImgLeft} />
                    </div>
                    <div className={styles.button} style={{right: "301px", top: "493px"}} onClick={() => this.props.submit(CLAIM_STATUS.REJECTED)}>
                        <div className={styles.buttonTextLeft}>Continue</div>
                        <img src={chevBlue} className={styles.buttonImgRight} />
                    </div>
                    <div id={styles.dispute} onClick={this.props.dispute}><u>Dispute Result</u></div>
                </div>
            );
        }
        else {
            return (
                <div id={styles.background}>
                    <div className={globalStyles.closeLarge} onClick={this.props.close}>
                        <img src={close} className={globalStyles.closeLargeImg} />
                    </div>
                    <img src={checkmark} id={styles.checkmark} />
                    <div id={styles.approvedTitle}>${this.props.amount} Reimbursement Approved</div>
                    <div className={styles.button} style={{left: "382px", top: "348px"}} onClick={() => this.props.submit(CLAIM_STATUS.ACCEPTED)}>
                        <div className={styles.buttonTextLeft}>Continue</div>
                        <img src={chevBlue} className={styles.buttonImgRight} />
                    </div>
                </div>
            );
        }
        return (
            <div id={styles.background}>
                <div className={globalStyles.closeLarge} onClick={this.props.close}>
                    <img src={close} className={globalStyles.closeLargeImg} />
                </div>
                <img src={checkmark} id={styles.checkmark} />
                <div id={styles.approvedTitle}>${50} Partial-Reimbursement<br />Approved</div>
                <div id={styles.barOuter}>
                    <div id={styles.totalBar}>
                        <div id={styles.coveredBar} style={{width: `${fractionCovered}%`}} />
                    </div>
                    <div className={styles.vertLine} style={{left: "0%"}} />
                    <div className={styles.vertLine} style={{left: `${fractionCovered}%`}} />
                    <div className={styles.vertLine} style={{left: "100%"}} />
                    <div className={styles.amount} style={{left: "0%"}}>$0</div>
                    <div className={styles.amount} style={{left: `${fractionCovered}%`}}><b>$50</b></div>
                    <div className={styles.amount} style={{left: "100%"}}>${this.props.amount}</div>
                    <div className={styles.coveredBy} style={{width: `${fractionCovered}%`, left: "0px", top: "25px"}}>Covered by Breeze</div>
                    <div className={styles.coveredBy} style={{left: `${fractionCovered}%`, top: "-30px", right: "0px"}}>Covered by You</div>
                </div>
                <div className={styles.button} style={{left: "382px", top: "488px"}} onClick={() => this.props.submit(CLAIM_STATUS.ACCEPTED)}>
                    <div className={styles.buttonTextLeft}>Continue</div>
                    <img src={chevBlue} className={styles.buttonImgRight} />
                </div>
            </div>
        );
    }
}
export default ResolutionForm;