import React from 'react';
import styles from './BagConfirmation.module.css';
import globalStyles from '../global.module.css';
import { ISSUE_CATEGORIES, DETAILS_STATES, LOADING_LOCS } from '../util';

import chevWhite from './images/chevWhite.svg';
import chevGrey from './images/chevGrey.svg';
import bags from './images/bags_blue.png';
import arrow from './images/arrow.png';
import person from './images/person_blue.png';

class BagConfirmation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shippingAddress1: "",
            shippingAddress2: "",
            shippingCity: "",
            shippingState: "",
            shippingZip: ""
        }

        this.allFieldsFilled = this.allFieldsFilled.bind(this);
    }

    allFieldsFilled() {
        return (this.state.shippingAddress1 != "" && this.state.shippingCity != "" && this.state.shippingState != "" && this.state.shippingZip != "");
    }

    render() {
        if (this.props.data.category != ISSUE_CATEGORIES.LOST_BAG) {
            return (<div />);
        }
        if (this.props.state < DETAILS_STATES.BAG_CONFIRMATION && !this.props.visited[DETAILS_STATES.BAG_CONFIRMATION]) {
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterPreview}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitlePreview}`}>Confirm</div>
                    {
                        LOADING_LOCS.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                    }
                </div>
            );
        }
        if (this.props.state == DETAILS_STATES.BAG_CONFIRMATION) {
            if (this.props.data.bagMatch == -1) {
                return (
                    <div>
                    <div className={globalStyles.stepOuter} style={{height: "368px", marginBottom: "-23px"}}>
                        <div className={globalStyles.stepTitle}>Submit Claim</div>
                        <div id={styles.bagNotFoundOuter}>
                            <img src={bags} id={styles.bagNotFoundImg1} />
                            <img src={arrow} id={styles.bagNotFoundImg2} />
                            <img src={person} id={styles.bagNotFoundImg3} />
                        </div>
                        <div id={styles.confirmTitle}>What happens next:</div>
                        <div id={styles.confirmSubtitle}>We are on the look out for your bag. When we find a bag we cannot identify, we will use the information you provided to match it back to you. Up next, you will receive an email update from us when we have more potential matches for you to look at.</div>
                    </div>
                    <div className={globalStyles.continue} onClick={() => this.props.submitClaim(0, "")}>
                        <div id={styles.selectText} style={{color: "white"}}>Submit Lost Bag</div>
                        <img src={chevWhite} className={globalStyles.continueArrow} />
                    </div>
                    </div>
                )
            }
            return (
                <div>
                <div className={globalStyles.stepOuter} style={{height: "548px", marginBottom: "-23px"}}>
                    <div className={globalStyles.stepTitle}>Confirm</div>
                    <img src={this.props.data.bagMatches[this.props.data.bagMatch].image} id={styles.confirmImg} />
                    <div id={styles.confirmTitle}>What happens next:</div>
                    <div id={styles.confirmSubtitle}>We have located your bag. We will work with UPS to get your bag back to you within 2-3 business days.</div>
                    <div className={styles.textInputOuter} style={{top: "235px"}}>
                        <input type="text" className={globalStyles.inputField} placeholder='123 Main St' onInput={(e) => this.setState({shippingAddress1: e.target.value})} />
                        <div className={globalStyles.inputFieldTitle}>Shipping Address Line 1</div>
                    </div>
                    <div className={styles.textInputOuter} style={{top: "334px"}}>
                        <input type="text" className={globalStyles.inputField} placeholder='' onInput={(e) => this.setState({shippingAddress2: e.target.value})} />
                        <div className={globalStyles.inputFieldTitle}>Shipping Address Line 2</div>
                    </div>
                    <div className={styles.textInputOuter} style={{top: "426px", width: "271px"}}>
                        <input type="text" className={globalStyles.inputField} placeholder='Chicago' onInput={(e) => this.setState({shippingCity: e.target.value})} />
                        <div className={globalStyles.inputFieldTitle}>City</div>
                    </div>
                    <div className={styles.textInputOuter} style={{top: "426px", width: "98px", left: "651px"}}>
                        <input type="text" className={globalStyles.inputField} placeholder='IL' onInput={(e) => this.setState({shippingState: e.target.value})} />
                        <div className={globalStyles.inputFieldTitle}>State</div>
                    </div>
                    <div className={styles.textInputOuter} style={{top: "426px", width: "131px", left: "766px"}}>
                        <input type="text" className={globalStyles.inputField} placeholder='60610' onInput={(e) => this.setState({shippingZip: e.target.value})} />
                        <div className={globalStyles.inputFieldTitle}>Zip Code</div>
                    </div>
                </div>
                {
                    this.allFieldsFilled() ? 
                    <div className={globalStyles.continue} onClick={() => this.props.submitClaim(0, "")}>
                        <div id={styles.selectText} style={{color: "white"}}>Ship Bag</div>
                        <img src={chevWhite} className={globalStyles.continueArrow} />
                    </div>
                    :
                    <div className={globalStyles.continueDisabled}>
                        <div id={styles.selectText} style={{color: "#A7A7A7"}}>Ship Bag</div>
                        <img src={chevGrey} className={globalStyles.continueArrow} />
                    </div>
                }
                </div>
            );
        }
        return (
            <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
                <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitleVisited}`}>Confirm</div>
                <div className={globalStyles.changeTrip} onClick={() => this.props.updateState(DETAILS_STATES.BAG_CONFIRMATION)}>
                    <img src={chevGrey} className={globalStyles.changeTripImg} />
                    <div className={globalStyles.changeTripText}>Change Selection</div>
                </div>
            </div>
        );
    }
}
export default BagConfirmation;