import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import { MENU_STATES } from '../util';

import IssueGrid from './IssueGrid';
import CreditCard from '../NotForm/CreditCard';
import Details from './Details';
import Resolution from './Resolution';

class Form extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.menuState == MENU_STATES.ISSUE) {
            return (
                <IssueGrid selectCategory={this.props.selectCategory} searchTerm={this.props.searchTerm} clearSearchTerm={this.props.clearSearchTerm} />
            );
        }
        else if (this.props.menuState == MENU_STATES.LOADING) {
            return (
                <DotLottieReact
                    src="https://lottie.host/57bd04fc-7f5e-4f92-8100-5c5a522bab1c/pVJ4VP82qK.lottie"
                    style={{width: "300px", height: "300px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
                    loop
                    autoplay
                />
            );
        }
        else if (this.props.menuState == MENU_STATES.DETAILS || this.props.menuState == MENU_STATES.CONFIRM) {
            return (
                <Details 
                    state={this.props.detailsState} 
                    statesVisited={this.props.detailsStatesVisited}
                    updateState={this.props.updateDetailsState} 
                    inputConfirmationNumber={this.props.inputConfirmationNumber}
                    changeFlights={this.props.changeFlights}
                    changeBags={this.props.changeBags}
                    changePassengers={this.props.changePassengers}
                    addClaim={this.props.addClaim}
                    addBagClaim={this.props.addBagClaim}
                    removeClaim={this.props.removeClaim}
                    removeBagClaim={this.props.removeBagClaim}
                    updatePaymentForm={this.props.updatePaymentForm}
                    submitClaim={this.props.submitClaim}
                    data={this.props.data} 
                    baseData={this.props.baseData} 
                    removeDisputed={this.props.removeDisputed} 
                    tellUsAboutYourBag={this.props.tellUsAboutYourBag}
                    selectBagMatch={this.props.selectBagMatch}
                    resetConfirmationCode={this.props.resetConfirmationCode} />
            );
        }
        else if (this.props.menuState == MENU_STATES.RESOLUTION) {
            return (
                <Resolution 
                    data={this.props.data}
                    baseData={this.props.baseData}
                    restartWorkflow={this.props.restartWorkflow} />
            );
        }
    }
}

export default Form;