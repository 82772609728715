import React from 'react';
import styles from './Details.module.css';

import Lookup from './Lookup';
import Flight from './Flight';
import Passengers from './Passengers';
import Eligibility from './Eligibility';
import Confirm from './Confirm';
import Feedback from './Feedback';
import Bags from './Bags';
import BagEligibility from './BagEligibility';
import BagIdentification from './BagIdentification';
import BagInformation from './BagInformation';
import BagConfirmation from './BagConfirmation';
import { DeletionAlert } from '../NotForm/Alerts';
import ClaimPopup from './ClaimSubmission/ClaimPopup';
import CreditCard from '../NotForm/CreditCard';
import { getBoolCount } from '../util';

class Details extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 0,
            alert: false,
            claimPopup: false,
            claimPopupCategory: "",
            claimPopupIsBag: false,
            creditCardPopup: false
        }

        this.submitPopup = this.submitPopup.bind(this);
    }

    componentDidMount() {
        this.setState({height: document.documentElement.scrollHeight});
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.height != document.documentElement.scrollHeight) {
            this.setState({height: document.documentElement.scrollHeight});
        }
    }

    submitPopup(claim) {
        if (this.state.claimPopupIsBag) {
            this.props.addBagClaim(claim);
        }
        else {
            this.props.addClaim(claim);
        }
        this.setState({claimPopup: false});
    }

    render() {
        return (
            <div>
            <div id={styles.detailsOuter}>
                <Lookup 
                    state={this.props.state} 
                    confirmation={this.props.data.confirmationCode} 
                    inputConfirmationNumber={this.props.inputConfirmationNumber}
                    updateState={this.props.updateState} 
                    deletePopup={() => this.setState({alert: true})} />
                <Flight 
                    state={this.props.state} 
                    data={this.props.data}
                    baseData={this.props.baseData}
                    visited={this.props.statesVisited}
                    changeFlights={this.props.changeFlights}
                    updateState={this.props.updateState} />
                <Passengers
                    state={this.props.state}
                    data={this.props.data}
                    baseData={this.props.baseData}
                    visited={this.props.statesVisited}
                    changePassengers={this.props.changePassengers}
                    updateState={this.props.updateState} />
                <Eligibility 
                    state={this.props.state} 
                    data={this.props.data}
                    baseData={this.props.baseData}
                    visited={this.props.statesVisited}
                    updateState={this.props.updateState}
                    addClaim={this.props.addClaim}
                    triggerPopup={(category) => this.setState({claimPopup: true, claimPopupCategory: category, claimPopupIsBag: false})}
                    triggerCreditCardPopup={() => this.setState({creditCardPopup: true})}
                    removeClaim={this.props.removeClaim} />
                <Feedback 
                    state={this.props.state}
                    data={this.props.data}
                    baseData={this.props.baseData}
                    visited={this.props.statesVisited} 
                    submitClaim={(issue) => this.props.submitClaim(0, issue)} />
                <Bags
                    state={this.props.state}
                    data={this.props.data}
                    baseData={this.props.baseData}
                    visited={this.props.statesVisited} 
                    updateState={this.props.updateState}
                    changeBags={this.props.changeBags} />
                <BagEligibility
                    state={this.props.state}
                    data={this.props.data}
                    baseData={this.props.baseData}
                    visited={this.props.statesVisited}
                    updateState={this.props.updateState} 
                    removeClaim={this.props.removeBagClaim}
                    triggerPopup={(category) => this.setState({claimPopup: true, claimPopupCategory: category, claimPopupIsBag: true})} />
                <BagInformation 
                    state={this.props.state}
                    data={this.props.data}
                    baseData={this.props.baseData}
                    visited={this.props.statesVisited}
                    tellUsAboutYourBag={this.props.tellUsAboutYourBag}  
                    updateState={this.props.updateState} />
                <BagIdentification
                    state={this.props.state}
                    data={this.props.data}
                    baseData={this.props.baseData}
                    visited={this.props.statesVisited}
                    updateState={this.props.updateState} 
                    selectBagMatch={this.props.selectBagMatch} />
                <BagConfirmation 
                    state={this.props.state}
                    data={this.props.data}
                    baseData={this.props.baseData}
                    visited={this.props.statesVisited}
                    updateState={this.props.updateState} 
                    submitClaim={this.props.submitClaim} />
                <Confirm 
                    state={this.props.state} 
                    data={this.props.data}
                    baseData={this.props.baseData}
                    visited={this.props.statesVisited}
                    updatePaymentForm={this.props.updatePaymentForm}
                    submitClaim={this.props.submitClaim} 
                    removeDisputed={this.props.removeDisputed} />
                <div id={styles.bufferSpace} />
                <div id={styles.vertLine} style={{height: `${this.state.height - 460}px`}} />
            </div>
            {
                this.state.alert ? 
                <DeletionAlert close={() => this.setState({alert: false})} delete={() => {this.setState({alert: false}); this.props.resetConfirmationCode()}} />
                :
                ""
            }
            {
                this.state.claimPopup ? 
                <ClaimPopup 
                    close={() => this.setState({claimPopup: false})} 
                    submit={this.submitPopup}
                    category={this.state.claimPopupCategory} 
                    baseData={this.props.baseData}
                    data={this.props.data}
                    passengerCount={getBoolCount(this.props.data.passengers)} />
                :
                ""
            }
            {
                this.state.creditCardPopup ? 
                <CreditCard close={() => this.setState({creditCardPopup: false})} flights={this.props.data.flights} />
                :
                <div />
            }
            </div>
        );
    }
}

export default Details;