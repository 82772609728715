// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Details_vertLine__O\\+mbA {
    position: absolute;
    top: 20px;
    left: 50%;
    width: 50%;
    bottom: 0px;
    border-left: 2px solid #DFDFDF;
    z-index: -100;
}

#Details_bufferSpace__G1jAH {
    position: relative;
    height: 20px;
}

#Details_detailsOuter__EUc5f {
    position: absolute;
    left: calc(50% - 260px);
    width: 982px;
    height: 50px;
    top: 390px;
}`, "",{"version":3,"sources":["webpack://./src/Form/Details.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,UAAU;IACV,WAAW;IACX,8BAA8B;IAC9B,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,YAAY;IACZ,UAAU;AACd","sourcesContent":["#vertLine {\n    position: absolute;\n    top: 20px;\n    left: 50%;\n    width: 50%;\n    bottom: 0px;\n    border-left: 2px solid #DFDFDF;\n    z-index: -100;\n}\n\n#bufferSpace {\n    position: relative;\n    height: 20px;\n}\n\n#detailsOuter {\n    position: absolute;\n    left: calc(50% - 260px);\n    width: 982px;\n    height: 50px;\n    top: 390px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vertLine": `Details_vertLine__O+mbA`,
	"bufferSpace": `Details_bufferSpace__G1jAH`,
	"detailsOuter": `Details_detailsOuter__EUc5f`
};
export default ___CSS_LOADER_EXPORT___;
