// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Outfit-VariableFont_wght.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/SF-Pro.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Font imports */
@font-face {
    font-family: "Outfit";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
@font-face {
    font-family: "SF Pro";
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}
/**/

#Lookup_textBox__lTSy7 {
    position: absolute;
    width: 892px;
    height: 68px;
    left: 30px;
    top: 100px;
    border-radius: 6px;
    border: 2px solid #3564E8;
}

#Lookup_search__GQq2Q {
    position: absolute;
    top: 195px;
    left: 30px;
    width: 132px;
    height: 45px;
    background-color: #2B3443;
    border-radius: 10px;
    cursor: pointer;
}

#Lookup_searchText__Xp3DE {
    position: absolute;
    left: 12px;
    top: 13px;
    color: white;
    font-size: 16px;
    font-family: "SF Pro", sans-serif;
    font-weight: 800;
}

#Lookup_searchImg__D-bee {
    position: absolute;
    width: 20px;
    top: 12px;
    left: 98px;
}

#Lookup_cantFind__bceQh {
    position: absolute;
    font-size: 13px;
    font-family: "SF Pro", sans-serif;
    font-weight: 500;
    color: #0089ED;
    cursor: pointer;
    left: 177px;
    top: 208px;
}`, "",{"version":3,"sources":["webpack://./src/Form/Lookup.module.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,qBAAqB;IACrB,+DAAoE;AACxE;AACA;IACI,qBAAqB;IACrB,+DAAkD;AACtD;AACA,GAAG;;AAEH;IACI,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,UAAU;IACV,UAAU;IACV,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,YAAY;IACZ,eAAe;IACf,iCAAiC;IACjC,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,SAAS;IACT,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,iCAAiC;IACjC,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,WAAW;IACX,UAAU;AACd","sourcesContent":["/* Font imports */\n@font-face {\n    font-family: \"Outfit\";\n    src: url('../fonts/Outfit-VariableFont_wght.ttf') format('truetype');\n}\n@font-face {\n    font-family: \"SF Pro\";\n    src: url('../fonts/SF-Pro.ttf') format('truetype');\n}\n/**/\n\n#textBox {\n    position: absolute;\n    width: 892px;\n    height: 68px;\n    left: 30px;\n    top: 100px;\n    border-radius: 6px;\n    border: 2px solid #3564E8;\n}\n\n#search {\n    position: absolute;\n    top: 195px;\n    left: 30px;\n    width: 132px;\n    height: 45px;\n    background-color: #2B3443;\n    border-radius: 10px;\n    cursor: pointer;\n}\n\n#searchText {\n    position: absolute;\n    left: 12px;\n    top: 13px;\n    color: white;\n    font-size: 16px;\n    font-family: \"SF Pro\", sans-serif;\n    font-weight: 800;\n}\n\n#searchImg {\n    position: absolute;\n    width: 20px;\n    top: 12px;\n    left: 98px;\n}\n\n#cantFind {\n    position: absolute;\n    font-size: 13px;\n    font-family: \"SF Pro\", sans-serif;\n    font-weight: 500;\n    color: #0089ED;\n    cursor: pointer;\n    left: 177px;\n    top: 208px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textBox": `Lookup_textBox__lTSy7`,
	"search": `Lookup_search__GQq2Q`,
	"searchText": `Lookup_searchText__Xp3DE`,
	"searchImg": `Lookup_searchImg__D-bee`,
	"cantFind": `Lookup_cantFind__bceQh`
};
export default ___CSS_LOADER_EXPORT___;
