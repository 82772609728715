import React from 'react';
import styles from './CreditCard.module.css';
import globalStyles from '../global.module.css';

import american from './images/creditCards/american.png';
import amexGold from './images/creditCards/amexGold.png';
import amexPlat from './images/creditCards/amexPlat.png';
import apple from './images/creditCards/apple.png';
import bilt from './images/creditCards/bilt.png';
import citi from './images/creditCards/citi.png';
import delta from './images/creditCards/delta.png';
import discover from './images/creditCards/discover.png';
import marriott from './images/creditCards/marriott.png';
import ritz from './images/creditCards/ritz.png';
import sapphire from './images/creditCards/sapphire.png';
import sapphirePreferred from './images/creditCards/sapphirePreferred.png';
import southwest from './images/creditCards/southwest.png';
import united from './images/creditCards/united.png';
import venture from './images/creditCards/venture.png';

import insuranceCoverageIcon from './images/insuranceCoverageIcon.png';
import close from '../Form/images/close.png';
import search from './images/search.svg';
import hotel from '../Form/images/hotel.svg';
import meals from './images/meals.svg';
import transportation from '../Form/images/transportation.svg';
import ganderlogo_grey from './images/ganderlogo_grey.svg';
import ganderlogo_blue from './images/ganderlogo_blue.svg';

class CreditCardTile extends React.Component {
    render() {
        return (
            <div className={styles.tileOuter} style={{top: `${this.props.row * 121 + 254}px`, left: `${this.props.col * 176 + 24}px`}} onClick={this.props.select}>
                <img src={this.props.img} className={styles.cardImg} />
            </div>
        );
    }
}

class PopupHeader extends React.Component {
    render() {
        return (
            <div className={globalStyles.popupHeader}>
                <div className={globalStyles.popupHeaderImgOuter}>
                    <img src={insuranceCoverageIcon} className={globalStyles.popupHeaderImg} />
                </div>
                <div className={globalStyles.popupHeaderTitle}>Insurance Coverage</div>
                <div className={globalStyles.closeLarge} onClick={this.props.close}>
                    <img src={close} className={globalStyles.closeLargeImg} />
                </div>
                <div id={styles.poweredBy}>Powered By</div>
                <img src={ganderlogo_grey} id={styles.headerLogo} />
                <div id={styles.ganderText}>Gander</div>
            </div>
        );
    }
}

class CreditCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            creditCards: [amexPlat, united, sapphire, ritz, venture, amexGold, delta, sapphirePreferred, bilt, citi, apple, marriott, american, southwest, discover],
            creditCardProviders: ["American Express", "Visa", "Visa", "Visa", "Visa | Mastercard", "American Express", "American Express", "Visa", "Mastercard", "Mastercard", "Mastercard", "American Express", "Mastercard", "Visa", "Discover"],
            creditCardName: ["Platinum Card", "United MileagePlus Explorer", "Chase Sapphire Reserve", "Ritz-Carlton", "CapitalOne Venture X", "Gold Card", "Delta Skymiles", "Chase Sapphire Preferred", "BILT", "Citi ThankYou Preferred", "Apple Card", "Marriott Bonvoy", "American Airlines AAdvantage", "Southwest Rapid Rewards", "it Card"],
            keywords: {
                "amex": [0, 5],
                "american": [0, 5, 12],
                "express": [0, 5],
                "platinum": [0],
                "gold": [5], 
                "sapphire": [2, 7],
                "chase": [2, 7],
                "reserve": [2],
                "preferred": [7],
                "apple": [10],
                "goldman": [10],
                "sachs": [10]
            },
            visibleCards: new Set(),
            searchVal: "",
            selectedCard: -1
        };

        this.updateSearch = this.updateSearch.bind(this);
    }

    updateSearch(term) {
        let keywords = term.split(" ");
        let visibleCards = new Set();
        for (let i = 0; i < keywords.length; i++)
        {
            if (this.state.keywords[keywords[i].toLowerCase()] != undefined)
            {
                for (let j = 0; j < this.state.keywords[keywords[i].toLowerCase()].length; j++) 
                {
                    visibleCards.add(this.state.keywords[keywords[i].toLowerCase()][j]);
                }
            }
        }
        this.setState({visibleCards: visibleCards});
    }

    render() {
        if (this.state.selectedCard == 0) {
            return (
                <div className={globalStyles.popupBackground} onClick={this.props.close}>
                    <div className={globalStyles.popupOuter} onClick={(e) => e.stopPropagation()}>
                        <PopupHeader close={this.props.close} />
                        <img src={this.state.creditCards[this.state.selectedCard]} id={styles.selectedCardImg} />
                        <div id={styles.selectedTitle}>{this.state.creditCardProviders[this.state.selectedCard]}</div>
                        <div id={styles.selectedSubtitle}>{this.state.creditCardName[this.state.selectedCard]}</div>
                        <div id={styles.goBackText} onClick={() => this.setState({selectedCard: -1})}>Back to Search Results</div>
                        <div id={styles.youAreCoveredOuter}>
                            <div id={styles.youAreCoveredTitle}>You are Covered for {}</div>
                            <div id={styles.youAreCoveredSubtitle}>Up to $500 for this flight</div>
                            <div id={styles.claimButton}>
                                <div id={styles.claimText}>Claim with Gander</div>
                                <img src={ganderlogo_blue} id={styles.claimLogo} />
                            </div>
                        </div>
                        <div className={styles.secondTitle} style={{left: "27px"}}>What is Covered</div>
                        <div className={styles.coveredOuter} style={{left: "28px"}}>
                            <div id={styles.coveredHeaderDiv}>
                                <div id={styles.coveredHeader}>$500 For All Delays (incl. Weather) Requiring Overnight Stay</div>
                            </div>
                            <img src={hotel} className={styles.coveredImg} style={{top: "72px"}} />
                            <div className={styles.coveredTitle} style={{top: "72px"}}>Hotel</div>
                            <div className={styles.coveredSubtitle} style={{top: "93px"}}>One hotel room for one night within 25 miles from {} Airport (towards $500 cap)</div>
                            <img src={meals} className={styles.coveredImg} style={{top: "151px", height: "50px", width: "50px", left: "20px"}} />
                            <div className={styles.coveredTitle} style={{top: "151px"}}>Meals</div>
                            <div className={styles.coveredSubtitle} style={{top: "172px"}}>Up to three meals during disruption period (towards $500 cap)</div>
                            <img src={transportation} className={styles.coveredImg} style={{top: "230px"}} />
                            <div className={styles.coveredTitle} style={{top: "230px"}}>Transport</div>
                            <div className={styles.coveredSubtitle} style={{top: "251px"}}>Round-trip transport to/from hotel or alternative transportation to your destination (towards $500 cap)</div>
                        </div>
                        <div className={styles.secondTitle} style={{left: "470px"}}>The Fine Print</div>
                        <div className={styles.coveredOuter} style={{left: "471px"}}>
                            <div id={styles.finePrintHeader}>Policy</div>
                            <div id={styles.fullPolicyLink}>Full Policy on <a href="https://www.amextravel.com" target="_blank">Amextravel.com</a></div>
                            <div className={styles.finePrintTitle} style={{top: "51px"}}>Receipt Details</div>
                            <div className={styles.finePrintSubtitle} style={{top: "73px"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
                            <div className={styles.finePrintTitle} style={{top: "231px"}}>Receipt Details</div>
                            <div className={styles.finePrintSubtitle} style={{top: "252px"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore </div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className={globalStyles.popupBackground} onClick={this.props.close}>
                <div className={globalStyles.popupOuter} onClick={(e) => e.stopPropagation()}>
                    <PopupHeader close={this.props.close} />
                    <div id={styles.title}>Look Up Your Credit Card</div>
                    <div id={styles.subtitle}>Your credit card may offer you coverage when Gander Airways can't</div>
                    <div id={styles.searchOuter}>
                        <img src={search} id={styles.searchImg} />
                        <input type="text" id={styles.search} placeholder='E.g Credit Card Name' onInput={(e) => this.updateSearch(e.target.value)} />
                        </div>
                    {
                        this.state.visibleCards.size > 0 ?
                        Array.from(this.state.visibleCards).map((cardIndex, index) => <CreditCardTile key={index} img={this.state.creditCards[cardIndex]} row={Math.floor(index / 5)} col={index % 5} select={() => this.setState({selectedCard: index})} />)
                        :
                        Array.from(this.state.creditCards).map((card, index) => <CreditCardTile key={index} img={card} row={Math.floor(index / 5)} col={index % 5} select={() => this.setState({selectedCard: index})} />)
                    }
                </div>
            </div>
        );
    }
}

export default CreditCard;