import React from 'react';
import styles from './DisputeForm.module.css';
import globalStyles from '../../global.module.css';
import { CLAIM_STATES } from '../../util';

import disputeLogo from '../images/disputeLogo.svg';
import close from '../images/close.png';
import submit from '../images/submit.png';
import review from '../images/review.png';
import resolution from '../images/resolution.png';
import arrow from '../images/arrow.svg';
import chevGrey from '../images/chevGrey.svg';

class DisputeForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            text: ""
        };
    }

    render() {
        if (this.props.state != CLAIM_STATES.DISPUTE) {
            return (<div />);
        }
        return (
            <div>
            <div className={globalStyles.popupHeader} style={{backgroundColor: "rgba(238, 231, 65, 0.3)"}}>
                <div className={globalStyles.popupHeaderImgOuter}>
                    <img src={disputeLogo} className={globalStyles.popupHeaderImg} />
                </div>
                <div className={globalStyles.popupHeaderTitle}>Dispute</div>
                <div className={globalStyles.closeLarge} onClick={this.props.close}>
                    <img src={close} className={globalStyles.closeLargeImg} />
                </div>
            </div>
            <div id={styles.vertLine} />
            <div className={styles.stepsImgOuter} style={{top: "142px"}}>
                <img className={styles.stepsImg} src={submit} />
            </div>
            <div className={styles.stepsImgOuter} style={{top: "274px"}}>
                <img className={styles.stepsImg} src={review} />
            </div>
            <div className={styles.stepsImgOuter} style={{top: "412px"}}>
                <img className={styles.stepsImg} src={resolution} />
            </div>
            <div className={styles.stepsTitle} style={{top: "156px"}}>Submit Dispute</div>
            <div className={styles.stepsSubtitle} style={{top: "184px"}}>Explain your dispute</div>
            <div className={styles.stepsTitle} style={{top: "282px"}}>Review</div>
            <div className={styles.stepsSubtitle} style={{top: "309px"}}>A human will review at Breeze Airways</div>
            <div className={styles.stepsTimeframe} style={{top: "330px"}}>Within 24-48 Hours</div>
            <div className={styles.stepsTitle} style={{top: "417px"}}>Get a Resolution</div>
            <div className={styles.stepsSubtitle} style={{top: "450px"}}>Come back to this system and your dispute result will be associated to your reservation</div>
            <div className={styles.stepsTimeframe} style={{top: "512px"}}>Within 24-48 Hours</div>
            <div id={styles.whatOuter}>
                <div className={styles.whatTitle}>What is Disputed</div>
                <div id={styles.whatText}>You are disputing a claim for a <b>${this.props.amount} {this.props.category}</b>. Our system rejected your request due to our records reflecting you were given a hotel voucher.</div>
            </div>
            <div id={styles.explainOuter}>
                <div className={styles.whatTitle}>Explain Your Dispute</div>
                <div id={styles.explainSubtitle}>Provide Dispute Details <span style={{color: "#FF8062"}}>*</span></div>
                <div id={styles.inputOuter}>
                    <textarea id={styles.explainText} onChange={(e) => this.setState({text: e.target.value})} placeholder="My claim was incorrectly rejected because..." />
                </div>
            </div>
            {
                this.state.text == "" ? 
                <div id={styles.submitOuter} style={{backgroundColor: "#FFEFEF"}}>
                    <div id={styles.submitText} style={{color: "#A7A7A7"}}>Submit Dispute</div>
                    <img src={chevGrey} id={styles.submitArrow} style={{transform: "rotate(-90deg)"}} />
                </div>
                :
                <div id={styles.submitOuter} onClick={this.props.submit}>
                    <div id={styles.submitText}>Submit Dispute</div>
                    <img src={arrow} id={styles.submitArrow} />
                </div>
            }
            </div>
        );
    }
}
export default DisputeForm;