import React from "react";
import styles from "./BagInformation.module.css";
import globalStyles from "../global.module.css";

import { DETAILS_STATES, ISSUE_CATEGORIES, LOADING_LOCS } from "../util";
import chevWhite from "./images/chevWhite.svg";
import chevGrey from "./images/chevGrey.svg";
import arrow from "./images/arrow.svg";

class BagInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            description: "",
            items: ["", "", ""],
            otherContents: ""
        }

        this.updateIdentifyingItem = this.updateIdentifyingItem.bind(this);
    }

    updateIdentifyingItem(e, index) {
        let items = this.state.items;
        items[index] = e.target.value;
        this.setState({items: items});
    }

    render() {
        if (this.props.data.category != ISSUE_CATEGORIES.LOST_BAG) {
            return (<div />);
        }
        if (this.props.state < DETAILS_STATES.BAG_INFORMATION && !this.props.visited[DETAILS_STATES.BAG_INFORMATION]) {
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterPreview}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitlePreview}`}>Lost Item Details</div>
                    {
                        LOADING_LOCS.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                    }
                </div>
            );
        }
        if (this.props.state == DETAILS_STATES.BAG_INFORMATION) {
            return (
                <div>
                <div className={globalStyles.stepOuter} style={{height: "766px",marginBottom: "-23px"}}>
                    <div className={globalStyles.stepTitle}>Tell Us About Your Bag</div>
                    <div className={styles.questionOuter} style={{top: "76px"}}>
                        <div className={styles.question}>Tell us about the exterior of your bag (shape, color, marks)? <span className={styles.required}>*</span></div>
                    </div>
                    <div className={styles.questionOuter} style={{top: "300px"}}>
                        <div className={styles.question}>What are the top three identifying items? <span className={styles.required}>*</span></div>
                    </div>
                    <div className={styles.questionOuter} style={{top: "522px"}}>
                        <div className={styles.question}>Describe any other contents of your bag? <span className={styles.required}>*</span></div>
                    </div>
                    <div className={styles.textAreaOuter} style={{top: "145px"}}>
                        <textarea className={styles.textAreaInput} placeholder="Ex. Blue Samsonite suitcase with grey and white stripes" onInput={(e) => this.setState({description: e.target.value})} />
                    </div>
                    <div className={styles.number} style={{top: "376px"}}>1</div>
                    <div className={styles.number} style={{top: "426px"}}>2</div>
                    <div className={styles.number} style={{top: "476px"}}>3</div>
                    <div className={styles.textOuter} style={{top: "367px"}}>
                        <input type="text" className={globalStyles.inputField} placeholder="Ex. Polka dot toiletry bag" value={this.state.items[0]} onChange={(e) => this.updateIdentifyingItem(e, 0)} style={{fontSize: "16px"}} />
                    </div>
                    <div className={styles.textOuter} style={{top: "417px"}}>
                        <input type="text" className={globalStyles.inputField} value={this.state.items[1]} onChange={(e) => this.updateIdentifyingItem(e, 1)} style={{fontSize: "16px"}} />
                    </div>
                    <div className={styles.textOuter} style={{top: "467px"}}>
                        <input type="text" className={globalStyles.inputField} value={this.state.items[2]} onChange={(e) => this.updateIdentifyingItem(e, 2)} style={{fontSize: "16px"}} />
                    </div>
                    <div className={styles.textAreaOuter} style={{top: "592px"}}>
                        <textarea className={styles.textAreaInput} placeholder="Ex. 2 pairs of jeans and a Twins baseball cap" onInput={(e) => this.setState({otherContents: e.target.value})} />
                    </div>
                </div>
                {
                    this.state.description != "" && this.state.otherContents != "" && this.state.items[0] != "" ? 
                    <div className={globalStyles.continue} onClick={() => this.props.tellUsAboutYourBag({description: this.state.description, items: this.state.items})}>
                        <div id={styles.selectText}>Continue</div>
                        <img src={chevWhite} className={globalStyles.continueArrow} />
                    </div>
                    :
                    <div className={globalStyles.continueDisabled}>
                        <div id={styles.selectText} style={{color: "#A7A7A7"}}>Continue</div>
                        <img src={chevGrey} className={globalStyles.continueArrow} />
                    </div>
                }
                </div>
            );
        }
        return (
            <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
                <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitleVisited}`}>Lost Item Details</div>
                <div className={globalStyles.changeTrip} onClick={() => this.props.updateState(DETAILS_STATES.BAG_INFORMATION)}>
                    <img src={arrow} className={globalStyles.changeTripImg} />
                    <div className={globalStyles.changeTripText}>Change Selection</div>
                </div>
            </div>
        );
    }
}
export default BagInformation;