import React from 'react';
import styles from './Header.module.css';

import ganderAirways from '../Form/images/ganderair.png';
import breezeAirways from '../Form/images/Breeze_Airways.svg.png';
import breezeLogoSmall from './images/breezeLogoSmall.png';

class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        // mobile and mobile stretch
        if (window.innerWidth < 1005) {
            return (
                <div id={styles.headerOuterMobile}>
                    <img id={styles.breezeLogoMobile} src={breezeAirways} />
                    {
                        !this.props.confirmation ? 
                        <a href="https://flybreeze.com" target="_blank">
                            <div id={styles.returnDivMobile}>
                                <img id={styles.breezeLogoSmall} src={breezeLogoSmall} />
                                flybreeze.com
                            </div>
                        </a>
                        :
                        <div id={styles.confCodeMobile}>
                            #{this.props.confirmation}
                        </div>
                    }
                </div>
            )
        }
        return (
            <div id={styles.headerOuter}>
                <img id={styles.breezeLogo} src={breezeAirways} />
                <a href="https://flybreeze.com" target="_blank">
                    <div id={styles.returnDiv}>
                    Back to flybreeze.com
                    </div>
                </a>
            </div>
        );
    }
}

export default Header;