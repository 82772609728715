import React from 'react';
import styles from './Menu.module.css';
import { DETAILS_STATES, getDetailsStateText } from '../util';

import menu0 from './images/menu0.svg';
import menu1 from './images/menu1.svg';
import menu2 from './images/menu2.svg';
import menu0_blue from './images/menu0_blue.svg';
import menu1_blue from './images/menu1_blue.svg';
import menu2_blue from './images/menu2_blue.svg';
import menu1_dark from './images/menu1_dark.svg';
import line from './images/line.svg';
import openMenu from './images/openMenu.svg';
import navUpBlack from './images/navUpBlack.svg';
import navDownBlack from './images/navDownBlack.svg';
import navUpGrey from './images/navUpGrey.svg';
import navDownGrey from './images/navDownGrey.svg';
import closeMenu from './images/closeMenu.png';

class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuExpanded: false
        }

        this.navUpAvailable = this.navUpAvailable.bind(this);
        this.navDownAvailable = this.navDownAvailable.bind(this);
    }

    navUpAvailable() {
        if (this.props.detailsState == DETAILS_STATES.LOOKUP) {
            return false;
        }
        return true;
    }

    navDownAvailable() {
        if (this.props.detailsState == DETAILS_STATES.COMPLETE) {
            return false;
        }
        return true;
    }

    render() {
        if (window.innerWidth < 1005) {
            let menuItems = Array.from(Array(6).keys());
            if (this.state.menuExpanded) {
                return (
                    <div id={styles.menuOuterExpandedMobile}>
                        <div id={styles.closeMenuMobile}>
                            <img src={closeMenu} id={styles.closeMenuMobileImg} onClick={() => this.setState({menuExpanded: false})} />
                            <div id={styles.closeMenuText} style={{color: "#FF8062"}}>Close Menu</div>
                        </div>
                        <div className={styles.menuItemMobile} style={{top: `${45}px`}}>
                            <div id={styles.menuTitleMobile}>Step 1: {getDetailsStateText(0)}</div>
                        </div>
                        <div className={styles.menuItemMobile} style={{top: `${43 + 45}px`}}>
                            <div id={styles.menuTitleMobile}>Step 2: {getDetailsStateText(1)}</div>
                        </div>
                        <div className={styles.menuItemMobile} style={{top: `${2 * 43 + 45}px`}}>
                            <div id={styles.menuTitleMobile}>Step 3: {getDetailsStateText(2)}</div>
                        </div>
                        <div className={styles.menuItemMobile} style={{top: `${3 * 43 + 45}px`}}>
                            <div id={styles.menuTitleMobile}>Step 4: {getDetailsStateText(3)}</div>
                        </div>
                        <div className={styles.menuItemMobile} style={{top: `${4 * 43 + 45}px`}}>
                            <div id={styles.menuTitleMobile}>Step 5: {getDetailsStateText(4)}</div>
                        </div>
                        <div className={styles.menuItemMobile} style={{top: `${5 * 43 + 45}px`}}>
                            <div id={styles.menuTitleMobile}>Step 6: {getDetailsStateText(5)}</div>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div id={styles.menuOuterClosedMobile}>
                        <img src={openMenu} id={styles.openMenuMobile} onClick={() => this.setState({menuExpanded: true})}/>
                        <div id={styles.menuTitleMobile}><b>Step {this.props.detailsState + 1}:</b> {getDetailsStateText(this.props.detailsState)}</div>
                        {this.navUpAvailable() ? <img src={navUpBlack} id={styles.navUpMobile} onClick={() => this.props.updateDetailsState(this.props.detailsState - 1)}/> : <img src={navUpGrey} id={styles.navUpMobile} />}
                        {this.navDownAvailable() ? <img src={navDownBlack} id={styles.navDownMobile} onClick={() => this.props.updateDetailsState(this.props.detailsState + 1)}/> : <img src={navDownGrey} id={styles.navDownMobile} />}
                    </div>
                )
            }
        }
        else if (this.props.state == 0)
        {
            return (
                <div id={styles.menu_outer}>
                    <img src={line} id={styles.line} />
                    <div className={styles.title} style={{top: "51px", color: "#3564E8"}}>Pick an Issue</div>
                    <div className={styles.subtitle} style={{top: "78px"}}>Select or search</div>
                    <div id={styles.circle1} style={{border: "2px solid #3564E8"}}>
                        <img src={menu0_blue} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "185px", color: "#979797"}}>Provide Us Details</div>
                    <div className={styles.subtitle} style={{top: "214px", color: "#979797"}}>Give us more details about your issue</div>
                    <div id={styles.circle2}>
                        <img src={menu1} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "323px", color: "#979797"}}>Get a Resolution</div>
                    <div className={styles.subtitle} style={{top: "350px", color: "#979797"}}>Our system will solve your issue</div>
                    <div id={styles.circle3}>
                        <img src={menu2} id={styles.img2} />
                    </div>
                </div>
            );
        }
        else if (this.props.state == 1)
        {
            return (
                <div id={styles.menu_outer}>
                    <img src={line} id={styles.line} />
                    <div className={styles.title} style={{top: "51px"}}>Pick an Issue</div>
                    <div className={styles.subtitle} style={{top: "78px"}}>Select or search</div>
                    <div id={styles.circle1}>
                        <img src={menu0} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "185px", color: "#3564E8"}}>Provide Us Details</div>
                    <div className={styles.subtitle} style={{top: "214px"}}>Give us more details about your issue</div>
                    <div id={styles.circle2} style={{border: "2px solid #3564E8"}}>
                        <img src={menu1_blue} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "323px", color: "#979797"}}>Get a Resolution</div>
                    <div className={styles.subtitle} style={{top: "350px", color: "#979797"}}>Our system will solve your issue</div>
                    <div id={styles.circle3}>
                        <img src={menu2} id={styles.img2} />
                    </div>
                </div>
            );
        }
        else if (this.props.state == 2)
        {
            return (
                <div id={styles.menu_outer}>
                    <img src={line} id={styles.line} />
                    <div className={styles.title} style={{top: "51px"}}>Pick an Issue</div>
                    <div className={styles.subtitle} style={{top: "78px"}}>Select or search</div>
                    <div id={styles.circle1}>
                        <img src={menu0} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "185px"}}>Provide Us Details</div>
                    <div className={styles.subtitle} style={{top: "214px"}}>Give us more details about your issue</div>
                    <div id={styles.circle2}>
                        <img src={menu1_dark} className={styles.img} />
                    </div>
                    <div className={styles.title} style={{top: "323px", color: "#3564E8"}}>Get a Resolution</div>
                    <div className={styles.subtitle} style={{top: "350px"}}>Our system will solve your issue</div>
                    <div id={styles.circle3} style={{border: "2px solid #3564E8"}}>
                        <img src={menu2_blue} id={styles.img2} />
                    </div>
                </div>
            );
        }
        else {
            return (<div />);
        }
}
}

export default Menu;