import React from 'react';
import styles from './Bags.module.css';
import globalStyles from '../global.module.css';
import { DETAILS_STATES, LOADING_LOCS, BAG_STATUS, TrimList, ISSUE_CATEGORIES } from '../util';

import chevGrey from './images/chevGrey.svg';
import chevWhite from './images/chevWhite.svg';
import checkmark from './images/checkmark.svg';
import suitcase from './images/suitcase.svg';
import arrow from './images/arrow.svg';

class BagStatus extends React.Component {
    render() {
        let text = "";
        let color = "";
        if (this.props.status == BAG_STATUS.DELIVERED) {
            text = "Delivered";
            color = "#84C631";
        }
        else if (this.props.status == BAG_STATUS.LATE) {
            text = "Delivered - Late";
            color = "#C3C3C3";

        }
        else if (this.props.status == BAG_STATUS.PENDING) {
            text = "Pending Delivery";
            color = "#FF8062";
        }
        if (this.props.small) {
            return (
                <div id={styles.canceledSelected} style={{backgroundColor: color, left: `${this.props.count == 1 ? 198 : 290}px`}}>
                    <div id={styles.canceledText} style={{fontSize: "10px"}}>{text}</div>
                </div>
            )
        }
        return (
            <div id={styles.canceled} style={{backgroundColor: color}}>
                <div id={styles.canceledText}>{text}</div>
            </div>
        );
    }
}

class IndividualBag extends React.Component {
    render() {
        let classOuter = styles.flight;
        if (this.props.data.bags[this.props.index]) {
            classOuter = `${styles.flight} ${styles.flightSelected}`;
        }
        let bagInfo = this.props.baseData.bags[this.props.index];
        return (
            <div className={classOuter} style={{top: `${81 + 103 * this.props.index}px`}} onClick={() => this.props.changeBags(this.props.index)}>
                {
                    this.props.data.bags[this.props.index] ? 
                    <div className={styles.selected}>
                        <img src={checkmark} className={styles.selectedImg} />
                    </div>
                    :
                    <div className={styles.selector} />
                }
                <img src={suitcase} className={styles.logo} />
                <div className={styles.bagNo}>Bag ID: {bagInfo.id}</div>
                <div className={styles.update}>{bagInfo.lastUpdate}</div>
                <BagStatus status={bagInfo.status} small={false} />
            </div>
        );
    }
}

class Bags extends React.Component {
    render() {
        if (this.props.data.category != ISSUE_CATEGORIES.BAG_DAMAGE && this.props.data.category != ISSUE_CATEGORIES.LOST_BAG) {
            return (<div />);
        }
        if (this.props.state == DETAILS_STATES.LOOKUP && !this.props.visited[DETAILS_STATES.BAGS]) {
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterPreview}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitlePreview}`}>Select Bags</div>
                    {
                        LOADING_LOCS.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                    }
                </div>
            )
        }
        if (this.props.state == DETAILS_STATES.BAGS) {
            return (
                <div>
                <div className={globalStyles.stepOuter} style={{height: `${this.props.data.category == ISSUE_CATEGORIES.LOST_BAG ? "304px" : "381px"}`,marginBottom: "-23px"}}>
                    <div className={globalStyles.stepTitle}>Select Bags</div>
                    {
                        this.props.data.bags.map((_, index) => 
                            <IndividualBag 
                                key={index} 
                                index={index} 
                                data={this.props.data} 
                                baseData={this.props.baseData} 
                                changeBags={this.props.changeBags} />)
                    }
                    {
                        this.props.data.category == ISSUE_CATEGORIES.LOST_BAG ?
                        ""
                        :
                        <div id={styles.selectAll} className={this.props.data.bags[0] && this.props.data.bags[1] ? styles.flightSelected : ""} onClick={() => this.props.changeBags(this.props.data.bags.length)}>
                            <div className={styles.selected} style={{top: "16px", backgroundColor: this.props.data.bags[0] && this.props.data.bags[1] ? "#84C631" : "white"}}>
                                <img src={checkmark} className={styles.selectedImg} style={{display: this.props.data.bags[0] && this.props.data.bags[1] ? "block" : "none"}} />
                            </div>
                            <div id={styles.selectAllText}>All Bags In Your Trip</div>
                        </div>
                    }
                </div>
                {
                    this.props.data.bags[0] || this.props.data.bags[1] ? 
                    <div className={globalStyles.continue} onClick={() => this.props.updateState(this.props.data.category == ISSUE_CATEGORIES.BAG_DAMAGE ? DETAILS_STATES.BAG_ELIGIBILITY : DETAILS_STATES.BAG_INFORMATION)}>
                        <div id={styles.selectText} style={{color: "white", left: "70px"}}>Select Bags</div>
                        <img src={chevWhite} className={globalStyles.continueArrow} />
                    </div>
                    :
                    <div className={globalStyles.continueDisabled}>
                        <div id={styles.selectText} style={{color: "#A7A7A7"}}>Confirm Eligibility</div>
                        <img src={chevGrey} className={globalStyles.continueArrow} />
                    </div>
                }
                </div>
            )
        }
        let status = BAG_STATUS.DELIVERED;
        let titleText = "Claiming Tags: ";
        if (this.props.data.bags[0]) {
            if (this.props.baseData.bags[0].status != BAG_STATUS.DELIVERED) {
                status = this.props.baseData.bags[0].status;
            }
            titleText += this.props.baseData.bags[0].id;
            titleText += ", ";
        }
        if (this.props.data.bags[1]) {
            if (this.props.baseData.bags[1].status != BAG_STATUS.DELIVERED) {
                status = this.props.baseData.bags[1].status;
            }
            titleText += this.props.baseData.bags[1].id;
        }
        titleText = TrimList(titleText);
        return (
            <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
                <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitleVisited}`}>{titleText}</div>
                <div className={globalStyles.changeTrip} onClick={() => this.props.updateState(DETAILS_STATES.BAGS)}>
                    <img src={arrow} className={globalStyles.changeTripImg} />
                    <div className={globalStyles.changeTripText}>Change Selection</div>
                </div>
            </div>
        )
    }
}
export default Bags;