import React from 'react';
import styles from './Lookup.module.css';
import globalStyles from '../global.module.css';
import { DETAILS_STATES } from '../util';
import { usePostHog } from 'posthog-js/react';

import search from './images/search.svg';
import arrow from './images/arrow.svg';

function Lookup(props) {
    const posthog = usePostHog();

    const loginClicked = () => {
        posthog.capture("input_confirmation_number", {confirmation: document.getElementById("confirmationNumber").value});
        props.inputConfirmationNumber(document.getElementById("confirmationNumber").value);
    }

    if (props.state == DETAILS_STATES.LOOKUP) {
        return (
            <div className={globalStyles.stepOuter} style={{height: "263px"}}>
                <div className={globalStyles.stepTitle}>Look Up Your Trip</div>
                <div id={styles.textBox}>
                    <input type="text" id="confirmationNumber" className={globalStyles.inputField} placeholder="e.x ABCGEF" />
                    <div className={globalStyles.inputFieldTitle}>Confirmation Number</div>
                </div>
                <div id={styles.search} onClick={loginClicked}>
                    <div id={styles.searchText}>Search</div>
                    <img src={search} id={styles.searchImg} />
                </div>
                <div id={styles.cantFind}><u>Can't Find Your Confirmation Number?</u></div>
            </div>
        )
    }
    return (
        <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
            <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitleVisited}`}>Confirmation Code: {props.confirmation}</div>
            <div className={globalStyles.changeTrip} onClick={props.deletePopup}>
                <div className={globalStyles.changeTripText}>Change Trip</div>
                <img src={arrow} className={globalStyles.changeTripImg} />
            </div>
        </div>
    );
}

export default Lookup;