import React from 'react';
import styles from './ClaimForm.module.css';
import globalStyles from '../../global.module.css';
import resolutionStyles from './ResolutionForm.module.css';
import { CLAIM_STATES, getErrorTitle, getErrorSubtitle, isInteger, returnInteger, LOADING_LOCS_SMALL } from '../../util';
import { BlobServiceClient } from '@azure/storage-blob';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import claimsTitle from '../images/claimsTitle.svg';
import upload from '../images/upload.svg';
import uploadWhite from '../images/uploadWhite.png';
import chevGrey from '../images/chevGrey.svg';
import chevWhite from '../images/chevWhite.svg';
import close from '../images/close.png';
import thinArrow from '../images/thinArrow.svg';
import caution from '../images/noEligible.svg';
import externalDamage from '../images/externalDamage.png';
import itemDamage from '../images/itemDamage.png';
import delayExpenses from '../images/delayExpenses.png';
import luggage from '../images/luggage.png';

class PolicyText extends React.Component {
    render() {
        if (this.props.category == "Hotel Reimbursement") {
            return (
                <div className={styles.policyTextOuter}>
                <div className={styles.policySubtitle}>What is Covered</div>
                <div className={styles.policyDetails}>Breeze Airways will cover one hotel night under ${this.props.policy.hotelReimbursement} for delays and cancellations within our immediate control that require a passenger to incur an overnight stay in a city in which they do not live. The hotel must have been booked after the notification of the delay or cancellation and must occur within the period the flight is disrupted. One stipend is available for every 2 passengers in a booking.</div>
                <div className={styles.policySubtitle}>Receipt Details</div>
                <div className={styles.policyDetails}>Please upload a receipt in (PDF, PNG, JPEG, DOCX) format that clearly highlights the name of the passenger, cost incurred by the passenger, location of the hotel, dates they stayed, and payment method. Any receipt not containing the above will not be accepted or will require additional documentation.</div>
                <div className={styles.policySubtitle}>Coverage Exceptions</div>
                <ul className={styles.policyDetails}>
                    <li>If the chosen hotel exceeds ${this.props.policy.hotelReimbursement}, you will be issued a partial refund capped at ${this.props.policy.hotelReimbursement} of value</li>
                    <li>Vacation rentals such as Airbnb are not eligible for reimbursements</li>
                    <li>Any hotel nights booked on or after {/* TODO */} are not eligible</li>
                    <li>Refunds without receipts are capped at $100</li>
                    <li>You were already given a hotel voucher for your stay</li>
                </ul>
                </div>
            )
        }
        else if (this.props.category == "Transportation Reimbursement") {
            return (
                <div className={styles.policyTextOuter}>
                <div className={styles.policySubtitle}>What is Covered</div>
                <div className={styles.policyDetails}>In cases where you must leave the airport for an overnight stay or to complete your journey via car, bus, ferry, or a flight on a different carrier, Breeze Airways may able to reimburse you for transportation.</div>
                <div className={styles.policyDetails}><b>For Transportation to/from Overnight Stay:</b> ${this.props.policy.transportationReimbursement} max</div>
                <div className={styles.policyDetails}><b>For Alternative Transportation to Destination:</b> reasonable expenses not exceeding 50% of the value of your ticket for roundtrip tickets and 100% of value for one-way iteneraries</div>
                <div className={styles.policySubtitle}>Receipt Details</div>
                <div className={styles.policyDetails}>The receipt should be uploaded as a JPG, PNG, or PDF and clearly dictate the date, amount paid, passenger, and payment method.</div>
                <div className={styles.policySubtitle}>What is not Covered</div>
                <ul className={styles.policyDetails}>
                    <li>Transportation +2 days after your originally scheduled departure</li>
                    <li>Transportation booked in advance of cancellation or delay notice</li>
                    <li >The trip must not be refunded to original payment methods</li>
                </ul>
                </div>
            )
        }
        else if (this.props.category == "Meals Reimbursement") {
            return (
                <div className={styles.policyTextOuter}>
                <div className={styles.policySubtitle}>What is Covered</div>
                <div className={styles.policyDetails}>For every 4 hours you are disrupted, you are eligible for ${this.props.policy.mealReimbursement} in food stipend per person.</div>
                <div className={styles.policySubtitle}>Receipt Details</div>
                <div className={styles.policyDetails}>Receipts must clearly state the amount and date of purchase. Please do not upload bank statements as they will not be accepted.</div>
                <div className={styles.policySubtitle}>What is not Covered</div>
                <ul className={styles.policyDetails}>
                    <li>Alcoholic beverages</li>
                    <li>Food purchased on-board Breeze Airways aircraft</li>
                </ul>
                </div>
            )
        }
        else if (this.props.category == "Delay Expenses") {
            return (
                <div className={styles.policyTextOuter}>
                <div className={styles.policySubtitle}>What is Covered</div>
                <div className={styles.policyDetails}>Breeze Airways is able to cover $200 in clothing expenses per day you are separated from your baggage. Each item should not exceed $100 and must be accompanied with a receipt to be accurately submitted. Any type of clothing will be accepted.</div>
                <div className={styles.policySubtitle}>Receipt Details</div>
                <div className={styles.policyDetails}>Please upload a receipt in (PDF, PNG, JPEG, DOCX) format that clearly highlights cost incurred by the passenger, location of purchase, date of purchase, and payment method. Any receipt not containing the above will not be accepted or will require additional documentation.</div>
                <div className={styles.policySubtitle}>Coverage Exceptions</div>
                <ul className={styles.policyDetails}>
                    <li>Items purchased in locations over 100km away from your location or on dates before your trip and after your baggage is delivered are not included.</li>
                    <li>Items that cannot be classified as clothing will be rejected unless the category has been changed in the toggle above.</li>
                </ul>
                </div>
            )
        }
        else if (this.props.category == "Item Damage") {
            return (
                <div>
                    <div>DAMAGe</div>
                </div>
            )
        }
        else if (this.props.category == "External Baggage Damage") {
            return (
                <div>
                    <div>EXTERNAL</div>
                </div>
            )
        }
        else if (this.props.category == "Lost Items") {
            return (
                <div>LOST</div>
            );
        }
        else {
            return (<div />);
        }
    }
}

class ReceiptUpload extends React.Component {
    render() {
        let filename = this.props.filename.split("\\");
        filename = filename[filename.length - 1];
        if (this.props.small) {
            if (this.props.filename == "") {
                return (
                    <div className={styles.uploadOuter} onClick={this.props.clickFileUpload} style={{top: this.props.top, width: "177px", left: "222px", display: this.props.display}}>
                        <div id={styles.uploadTextSmall}>Upload Original Receipt</div>
                        <img src={upload} id={styles.uploadImg} style={{left: "16px"}} />
                    </div>
                )
            }
            else {
                return (
                    <div className={styles.uploadOuter} style={{cursor: "auto", top: this.props.top, width: "177px", left: "222px", display: this.props.display}}>
                        <div id={styles.filename} style={{left: "10px", right: "40px", overflow: "hidden"}}>{filename}</div>
                        <div className={styles.vertLine} style={{left: "138px"}} />
                        <div id={styles.closeSmallOuter} onClick={this.props.clear}>
                            <img src={close} id={styles.closeSmallImg} />
                        </div>
                    </div>
                )
            }
        }
        if (this.props.filename == "") {
            return (
                <div className={styles.uploadOuter} onClick={this.props.clickFileUpload} style={{top: this.props.top, display: this.props.display}}>
                    <div id={styles.uploadText}>Upload a Receipt</div>
                    <img src={upload} id={styles.uploadImg} />
                </div>
            );
        }
        else {
            return (
                <div className={styles.uploadOuter} style={{cursor: "auto", top: this.props.top, display: this.props.display}}>
                    <div id={styles.filename}>{filename}</div>
                    <div className={styles.vertLine} />
                    <div id={styles.closeSmallOuter} onClick={this.props.clear}>
                        <img src={close} id={styles.closeSmallImg} />
                    </div>
                </div>
            )
        }
    }
}

class PopupHeader extends React.Component {
    constructor(props) {
        super(props);

        this.textCallback = this.textCallback.bind(this);
    }

    textCallback(e, cat) {
        if (this.props.delayCategory == cat) {
            e.stopPropagation();
        }
        else {
            this.props.toggleCategory(cat);
            e.stopPropagation();
        }
    }

    render() {
        let icon = claimsTitle;
        if (this.props.category == "External Bag Damage") {
            icon = externalDamage;
        }
        else if (this.props.category == "Item Damage") {
            icon = itemDamage;
        }
        else if (this.props.category == "Delay Expenses") {
            icon = delayExpenses;
        }
        else if (this.props.category == "Lost Items") {
            icon = luggage;
        }
        return (
            <div className={globalStyles.popupHeader}>
                <div className={globalStyles.popupHeaderImgOuter}>
                    <img src={icon} className={globalStyles.popupHeaderImg} />
                </div>
                <div className={globalStyles.popupHeaderTitle}>{this.props.category}</div>
                {
                    this.props.category == "Delay Expenses" ? 
                    <div id={styles.receiptToggleOuter} style={{width: "474px"}}>
                        <div className={styles.receiptToggleBackground} style={{left: "0px"}} onClick={() => this.props.toggleCategory(0)} />
                        <div className={styles.receiptToggleBackground} style={{left: "25%"}} onClick={() => this.props.toggleCategory(1)} />
                        <div className={styles.receiptToggleBackground} style={{left: "50%"}} onClick={() => this.props.toggleCategory(2)} />
                        <div className={styles.receiptToggleBackground} style={{left: "75%"}} onClick={() => this.props.toggleCategory(3)} />
                        <div id={styles.receiptToggle} style={{left: `calc(${this.props.delayCategory * 25}% + 2px)`, width: "115px"}} onClick={(e) => e.stopPropagation()}/>
                        <div className={styles.receiptToggleText} style={{left: "10px"}} onClick={(e) => {this.textCallback(e, 0)}}>Clothing</div>
                        <div className={styles.receiptToggleText} style={{left: "131px"}} onClick={(e) => {this.textCallback(e, 1)}}>Medicine</div>
                        <div className={styles.receiptToggleText} style={{left: "248px"}} onClick={(e) => {this.textCallback(e, 2)}}>Equiptment Rental</div>
                        <div className={styles.receiptToggleText} style={{left: "366px"}} onClick={(e) => {this.textCallback(e, 3)}}>Other</div>
                    </div>
                    :
                    <div id={styles.receiptToggleOuter} onClick={this.props.toggleReceipt}>
                        <div id={styles.receiptToggle} style={{left: `${this.props.receipt ? 2 : 110}px`}} onClick={(e) => e.stopPropagation()}/>
                        <div className={styles.receiptToggleText} style={{left: "2px"}} onClick={(e) => {if (this.props.receipt) {e.stopPropagation()}}}>Receipt</div>
                        <div className={styles.receiptToggleText} style={{right: "2px"}} onClick={(e) => {if (!this.props.receipt) {e.stopPropagation()}}}>No Receipt</div>
                    </div>
                }
                <div className={globalStyles.closeLarge} onClick={this.props.close}>
                    <img src={close} className={globalStyles.closeLargeImg} />
                </div>
            </div>
        )
    }
}

class ItemDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdown: false
        }
    }

    render() {
        if (this.state.dropdown) {
            return (
                <div id={styles.dropdownOuter} style={{height: `${(this.props.array.length + 1) * 45}px`}}>
                    <div id={styles.chooseCategoryText}>{this.props.indexSelected == -1 ? "Choose an item" : this.props.indexSelected == -2 ? "None Selected" : this.props.array[this.props.indexSelected]}</div>
                    <div className={styles.vertLine} />
                    <div id={styles.dropdownDiv} onClick={() => this.setState({dropdown: !this.state.dropdown})}>
                        <img src={thinArrow} id={styles.dropdownImg} style={{transform: `rotate(${this.state.dropdown ? 180 : 0}deg)`}} />
                    </div>
                    {
                        this.props.array.map((category, index) => 
                            <div key={index} className={styles.dropdownCategory} style={{top: `${45 + index * 45}px`}} onClick={() => {this.props.setIndexSelected(index); this.setState({dropdown: false})}}>{category}</div>)
                    }
                </div>
            );
        }
        else {
            return (
                <div className={styles.uploadOuter} style={{top: "79px", cursor: "auto"}}> 
                    <div id={styles.chooseCategoryText}>{this.props.indexSelected == -1 ? "Choose an item" : this.props.indexSelected == -2 ? "None Selected" : this.props.array[this.props.indexSelected]}</div>
                    <div className={styles.vertLine} />
                    <div id={styles.dropdownDiv} onClick={() => this.setState({dropdown: true})}>
                        <img src={thinArrow} id={styles.dropdownImg} />
                    </div>
                </div>
            );
        }
    }
}

class ClaimForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            receipt: true,
            delayCategory: 0,
            filename: "",
            file: null,
            originalFilename: "",
            amount: "",
            expenseDetails: "",
            expenseTitle: "",
            category: "",
            dontHaveReceipt: false,
            indexSelected: -1,
            items: ["WRM WINT SCRF ($34)", "MITTENS - PAIR ($12)", "WATERPROOF JACKET ($25)"]
        };

        this.clickFileUpload = this.clickFileUpload.bind(this);
        this.saveFileUpload = this.saveFileUpload.bind(this);
        this.executeFileUpload = this.executeFileUpload.bind(this); 
        this.executeFileUploadFromState = this.executeFileUploadFromState.bind(this);
        this.isFormFilled = this.isFormFilled.bind(this);
    }

    clickFileUpload(newReceipt) {
        let fileUpload;
        if (newReceipt) {
            fileUpload = document.getElementById("fileUpload");
        }
        else {
            fileUpload = document.getElementById("originalReceiptFileUpload");
        }
        
        fileUpload.click();
    }

    saveFileUpload(e) {
        this.setState({filename: e.target.value, file: e.target.files[0]})
    }

    async executeFileUpload(file) {
        let storageAccountName = "ganderstorageaccount";
        let sig = "sp=racwdli&st=2024-11-11T22:47:01Z&se=2025-05-12T05:47:01Z&sv=2022-11-02&sr=c&sig=oMDUszaqTPNW%2BRmcBi%2Fw9xrCSG%2B7%2B5ezM7ar%2BwVYXFk%3D";
        const blobService = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net?${sig}`);
        const containerClient = blobService.getContainerClient("receipts");
        const blobClient = containerClient.getBlockBlobClient(file.name);
        const options = { blobHTTPHeaders: { blobContentType: file.type } };
        await blobClient.uploadBrowserData(file, options);
        this.props.submit(file.name, returnInteger(this.state.amount), this.props.category == "Hotel Reimbursement" || this.props.category == "Transportation Reimbursement" || this.props.category == "Meals Reimbursement" || this.props.category == "Other Reimbursement");
    }

    async executeFileUploadFromState() {
        this.executeFileUpload(this.state.file)
    }

    isFormFilled() {
        if (this.props.category == "External Bag Damage" || this.props.category == "Item Damage") {
            return isInteger(this.state.amount) && ((this.state.receipt && this.state.filename != "" && this.state.originalFilename != "" && this.state.expenseDetails != "") || (!this.state.receipt && this.state.expenseDetails != ""));
        }
        else if (this.props.category == "Delay Expenses") {
            return isInteger(this.state.amount) && ((this.state.receipt && this.state.filename != "" && this.state.expenseDetails != "") || (!this.state.receipt && this.state.expenseDetails != ""));
        }
        return isInteger(this.state.amount) && ((this.state.receipt && this.state.filename != "") || (!this.state.receipt && this.state.expenseDetails != ""));
    }

    render() {
        if (this.props.state == CLAIM_STATES.CLAIM) {
            let receiptName = "Receipt";
            if (this.props.category == "Item Damage") {
                receiptName = "Item Condition";
            }
            else if (this.props.category == "External Bag Damage") {
                receiptName = "Bag Condition";
            }
            if (this.props.category == "Other Reimbursement") {
                return (
                    <div>
                    <PopupHeader
                        toggleReceipt={() => this.setState({receipt: !this.state.receipt})} 
                        toggleCategory={(newCategory) => this.setState({delayCategory: newCategory})}
                        receipt={this.state.receipt} 
                        close={this.props.close}
                        category={this.props.category} 
                        delayCategory={this.state.delayCategory} />
                    <div id={styles.otherOuter}>
                        <div className={styles.policyTitle}>Submit a Claim</div>
                        <div className={styles.inputTitle} style={{top: "57px"}}>Category <span style={{color: "#FF8062"}}>*</span></div>
                        {
                            this.state.dropdown ? 
                            <div id={styles.dropdownOuter}>
                                <div id={styles.chooseCategoryText}>{this.state.category == "" ? "Choose a category" : this.state.category}</div>
                                <div className={styles.vertLine} />
                                <div id={styles.dropdownDiv} onClick={() => this.setState({dropdown: !this.state.dropdown})}>
                                    <img src={thinArrow} id={styles.dropdownImg} style={{transform: `rotate(${this.state.dropdown ? 180 : 0}deg)`}} />
                                </div>
                                {
                                    ["Comfort Items", "Parking", "Airport WiFi", "Lounge Pass", "Medication", "Other"].map((category, index) => 
                                        <div key={index} className={styles.dropdownCategory} style={{top: `${45 + index * 45}px`}} onClick={() => this.setState({category: category, dropdown: false})}>{category}</div>)
                                }
                            </div>
                            :
                            <div className={styles.uploadOuter} style={{top: "79px", cursor: "auto"}}> 
                                <div id={styles.chooseCategoryText}>{this.state.category == "" ? "Choose a category" : this.state.category}</div>
                                <div className={styles.vertLine} />
                                <div id={styles.dropdownDiv} onClick={() => this.setState({dropdown: true})}>
                                    <img src={thinArrow} id={styles.dropdownImg} />
                                </div>
                            </div>
                        }
                        <div className={styles.inputTitle} style={{top: "134px"}}>Amount Requested <span style={{color: "#FF8062"}}>*</span></div>
                        <div id={styles.amountInputOuter} style={{top: "158px"}}>
                            <input type="text" className={globalStyles.inputField} style={{left: "4px", paddingLeft: "16px", fontSize: "14px"}} placeholder={`Max: $${this.props.policy.hotelReimbursement}`} onChange={(e) => this.setState({amount: e.target.value})} />
                        </div>
                        {
                            this.state.receipt ? 
                            <div>
                                <div className={styles.inputTitle} style={{top: "213px"}}>Receipt (PDF, PNG, JPEG) <span style={{color: "#FF8062"}}>*</span></div>
                                <ReceiptUpload display="inline" filename={this.state.filename} clickFileUpload={() => this.clickFileUpload(true /*new receipt*/)} clear={() => this.setState({filename: ""})} top={"240px"} small={false} />
                                <input type="file" style={{display: "none"}} id="fileUpload" onChange={this.saveFileUpload}/>
                            </div>
                            :
                            <div />
                        }
                        <div className={styles.inputTitle} style={{top: "57px", left: "417px"}}>Provide Expense Details (100 Word Max) <span style={{color: "#FF8062", display: `${this.state.receipt ? "none" : "inline"}`}}>*</span></div>
                        <div id={styles.inputOuter} style={{top: "79px", height: "371px", left: "417px", width: "423px"}}>
                            <textarea id={styles.input} placeholder='E.g My flight has been delayed' onInput={(e) => this.setState({expenseDetails: e.target.value})} />
                        </div>
                    </div>
                    {
                        this.state.category != "" && isInteger(this.state.amount) && ((this.state.receipt && this.state.filename != "") || (!this.state.receipt && this.state.expenseDetails != ""))? 
                        <div id={styles.fileClaim} style={{backgroundColor: "#84C631"}} onClick={this.executeFileUploadFromState}>
                            <div id={styles.fileClaimText} style={{color: "white"}}>File Claim</div>
                            <img src={chevWhite} id={styles.fileClaimImg} />
                        </div>
                        :
                        <div id={styles.fileClaim} style={{backgroundColor: "#FFEFEF"}}>
                            <div id={styles.fileClaimText} style={{color: "#A7A7A7"}}>File Claim</div>
                            <img src={chevGrey} id={styles.fileClaimImg} />
                        </div>
                    }
                    </div>
                );
            }
            else if (this.props.category == "External Bag Damage" || this.props.category == "Item Damage" || this.props.category == "Delay Expenses" || this.props.category == "Lost Items") {
                return (
                    <div>
                    <PopupHeader
                        toggleReceipt={() => this.setState({receipt: !this.state.receipt})} 
                        toggleCategory={(newCategory) => this.setState({delayCategory: newCategory})}
                        receipt={this.state.receipt} 
                        delayCategory={this.state.delayCategory}
                        close={this.props.close}
                        category={this.props.category} />
                    {
                        this.props.errors.length > 0 ? 
                        <div id={styles.errorOuter}>
                            <div id={styles.errorTitle}>{getErrorTitle(this.props.errors[0])}</div>
                            <div id={styles.errorSubtitle}>{getErrorSubtitle(this.props.errors[0])}</div>
                            <img src={caution} id={styles.errorImg} />
                            <div className={styles.errorCircle}>
                                <div id={styles.selectedCircle} />
                            </div>
                            <img src={chevGrey} id={styles.navUp} />
                            <img src={chevGrey} id={styles.navDown} />
                        </div>
                        :
                        <div />
                    }
                    <div className={styles.policyOuter} style={{left: "19px", top: `${this.props.errors.length > 0 ? 159 : 89}px`}}>
                        <div className={styles.policyTitle}>Policy</div>
                        <PolicyText category={this.props.category} policy={this.props.baseData.airlineInfo.policy} />
                    </div>
                    <div className={styles.policyOuter} style={{right: "19px", top: `${this.props.errors.length > 0 ? 159 : 89}px`}}>
                        {
                            this.state.filename ? 
                            <div>
                                <div className={styles.policyTitle}>Expense Details</div>
                                <div className={styles.policySubtitle} style={{top: "52px"}}>Select an Item from the Receipt</div>
                                <ItemDropdown indexSelected={this.state.indexSelected} setIndexSelected={(newIndex) => { console.log("New index selected", newIndex, this.props.docIQResponse); this.setState({indexSelected: newIndex, amount: this.props.docIQResponse && newIndex >= 0 ? this.props.docIQResponse.prices[newIndex] : ""})}} array={this.props.docIQResponse ? this.props.docIQResponse.items : this.state.items} />
                                {
                                    this.state.indexSelected == -1 ? 
                                    <div>
                                        <div id={styles.noReceiptSubtitle} style={{top: "142px"}} onClick={() => this.setState({indexSelected: -2})}><u>None Match? Enter Manually</u></div>
                                        {
                                            LOADING_LOCS_SMALL.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                                        }
                                    </div>
                                    :
                                    <div>
                                        <div className={styles.inputTitle} style={{top: "144px"}}>Item Description <span style={{color: "#FF8062"}}>*</span></div>
                                        <div id={styles.amountInputOuter} style={{width: "290px", top: "168px"}}>
                                            <input type="text" className={globalStyles.inputField} style={{left: "4px", paddingLeft: "16px", fontSize: "14px"}} placeholder="E.g Describe the item" value={this.state.expenseTitle} onChange={(e) => this.setState({expenseTitle: e.target.value})} />
                                        </div>
                                        <div className={styles.inputTitle} style={{top: "144px", left: "326px"}}>Price <span style={{color: "#FF8062"}}>*</span></div>
                                        <div id={styles.amountInputOuter} style={{width: "75px", left: "326px", top: "168px"}}>
                                            <input type="text" className={globalStyles.inputField} style={{left: "4px", paddingLeft: "5px", fontSize: "14px"}} placeholder="$25" value={this.state.amount} onChange={(e) => this.setState({amount: e.target.value})} />
                                        </div>
                                        <div className={styles.inputTitle} style={{top: "225px"}}>Provide Item Rationale (100 word max) <span style={{color: "#FF8062"}}>*</span></div>
                                        <div id={styles.inputOuter} style={{top: "232px", height: "192px", top: "248px"}}>
                                            <textarea id={styles.input} placeholder='E.g My flight has been delayed' onInput={(e) => this.setState({expenseDetails: e.target.value})} />
                                        </div>
                                        {
                                            this.state.amount && this.state.expenseTitle && this.state.expenseDetails ? 
                                            <div id={styles.fileClaim} style={{backgroundColor: "#84C631"}} onClick={this.executeFileUploadFromState}>
                                                <div id={styles.fileClaimText} style={{color: "white"}}>File Claim</div>
                                                <img src={chevWhite} id={styles.fileClaimImg} />
                                            </div>
                                            :
                                            <div id={styles.fileClaim} style={{backgroundColor: "#FFEFEF"}}>
                                                <div id={styles.fileClaimText} style={{color: "#A7A7A7"}}>File Claim</div>
                                                <img src={chevGrey} id={styles.fileClaimImg} />
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                            :
                            <div>
                                <div id={styles.noReceiptTitle}>Upload a Receipt</div>
                                <div id={styles.uploadReceiptOuter} onClick={() => this.clickFileUpload(true /*original receipt*/)}>
                                    <div id={styles.uploadReceiptText}>Upload</div>
                                    <img src={uploadWhite} id={styles.uploadReceiptImg} />
                                    <input type="file" style={{display: "none"}} id="fileUpload" onChange={(e) => {this.saveFileUpload(e); this.executeFileUpload(e.target.files[0])}}/>
                                </div>
                                {
                                    this.state.dontHaveReceipt ? 
                                    <div id={styles.noReceiptBanner}>
                                        <div id={styles.noReceiptBannerTitle}>Receipts are Required</div>
                                        <div id={styles.noReceiptBannerSubtitle}>Breeze Airways requires receipts for all lost-bag expenses</div>
                                        <img src={close} id={styles.noReceiptBannerClose} onClick={() => this.setState({dontHaveReceipt: false})}/>
                                    </div>
                                    :
                                    ""
                                }
                                <div id={styles.noReceiptSubtitle} onClick={() => this.setState({dontHaveReceipt: !this.state.dontHaveReceipt})}><u>Don't have a Receipt?</u></div>
                            </div>
                        }
                    </div>
                    </div>
                );
            }
            return (
                <div>
                <PopupHeader
                    toggleReceipt={() => this.setState({receipt: !this.state.receipt})} 
                    toggleCategory={(newCategory) => this.setState({delayCategory: newCategory})}
                    receipt={this.state.receipt} 
                    delayCategory={this.state.delayCategory}
                    close={this.props.close}
                    category={this.props.category} />
                {
                    this.props.errors.length > 0 ? 
                    <div id={styles.errorOuter}>
                        <div id={styles.errorTitle}>{getErrorTitle(this.props.errors[0])}</div>
                        <div id={styles.errorSubtitle}>{getErrorSubtitle(this.props.errors[0])}</div>
                        <img src={caution} id={styles.errorImg} />
                        <div className={styles.errorCircle}>
                            <div id={styles.selectedCircle} />
                        </div>
                        <img src={chevGrey} id={styles.navUp} />
                        <img src={chevGrey} id={styles.navDown} />
                    </div>
                    :
                    <div />
                }
                <div className={styles.policyOuter} style={{left: "19px", top: `${this.props.errors.length > 0 ? 159 : 89}px`}}>
                    <div className={styles.policyTitle}>Policy</div>
                    <PolicyText category={this.props.category} policy={this.props.baseData.airlineInfo.policy} />
                </div>
                <div className={styles.policyOuter} style={{right: "19px", top: `${this.props.errors.length > 0 ? 159 : 89}px`}}>
                    <div className={styles.policyTitle}>Submit a Claim</div>
                    {
                        this.isFormFilled() ? 
                        <div id={styles.fileClaim} style={{backgroundColor: "#84C631"}} onClick={this.executeFileUploadFromState}>
                            <div id={styles.fileClaimText} style={{color: "white"}}>File Claim</div>
                            <img src={chevWhite} id={styles.fileClaimImg} />
                        </div>
                        :
                        <div id={styles.fileClaim} style={{backgroundColor: "#FFEFEF"}}>
                            <div id={styles.fileClaimText} style={{color: "#A7A7A7"}}>File Claim</div>
                            <img src={chevGrey} id={styles.fileClaimImg} />
                        </div>
                    }
                    <div className={styles.inputTitle} style={{top: "54px"}}>Amount Requested <span style={{color: "#FF8062"}}>*</span></div>
                    {
                        this.props.category == "External Bag Damage" || this.props.category == "Item Damage" || this.props.category == "Lost Items" ? 
                        <div>
                        <div id={styles.amountInputOuter} style={{width: `${this.state.receipt ? 187 : 379}px`}}>
                            <input type="text" className={globalStyles.inputField} style={{left: "4px", paddingLeft: "16px", fontSize: "14px"}} placeholder="Max: $200" value={this.state.amount} onChange={(e) => this.setState({amount: e.target.value})} />
                        </div>
                        <ReceiptUpload display={`${this.state.receipt ? "Inline" : "None"}`} filename={this.state.originalFilename} clickFileUpload={() => this.clickFileUpload(false /*original receipt*/)} clear={() => this.setState({originalFilename: ""})} top={"78px"} small={true} />
                        <input type="file" style={{display: "none"}} id="originalReceiptFileUpload" onChange={(e) => this.setState({originalFilename: e.target.value})}/>
                        </div>
                        :
                        <div id={styles.amountInputOuter} style={{width: `${379}px`}}>
                            <input type="text" className={globalStyles.inputField} style={{left: "4px", paddingLeft: "16px", fontSize: "14px"}} placeholder="Max: $200" value={this.state.amount} onChange={(e) => this.setState({amount: e.target.value})} />
                        </div>
                    }
                    {
                        this.state.receipt ? 
                        <div>
                            <div className={styles.inputTitle} style={{top: "133px"}}>{receiptName} (PDF, PNG, JPEG) <span style={{color: "#FF8062"}}>*</span></div>
                            <ReceiptUpload display="inline" filename={this.state.filename} clickFileUpload={() => this.clickFileUpload(true /*new receipt*/)} clear={() => this.setState({filename: ""})} top={"155px"} small={false} />
                            <input type="file" style={{display: "none"}} id="fileUpload" onChange={this.saveFileUpload}/>
                        </div>
                        :
                        <div />
                    }
                    <div className={styles.inputTitle} style={{top: `${this.state.receipt ? 210 : 133}px`}}>Provide Expense Details (100 word max) <span style={{color: "#FF8062", display: `${(!this.state.receipt || this.props.category == "Item Damage" || this.props.category == "External Bag Damage" || this.props.category == "Delay Expenses") ? "inline" : "none"}`}}>*</span></div>
                    <div id={styles.inputOuter} style={{top: `${this.state.receipt ? 232 : 155}px`, height: `${this.state.receipt ? (this.props.errors.length > 0 ? 145 : 215) : (this.props.errors.length > 0 ? 222 : 292)}px`}}>
                        <textarea id={styles.input} placeholder='E.g My flight has been delayed' onInput={(e) => this.setState({expenseDetails: e.target.value})} />
                    </div>
                </div>
                </div>
            );
        }
        else if (this.props.state == CLAIM_STATES.LOADING) {
            return (
                <DotLottieReact
                    src="https://lottie.host/57bd04fc-7f5e-4f92-8100-5c5a522bab1c/pVJ4VP82qK.lottie"
                    style={{width: "300px", height: "300px", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}
                    loop
                    autoplay
                />
            );
        }
        else {return (<div />);}
    }
}
export default ClaimForm;