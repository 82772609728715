import React from 'react';
import styles from './IssueGrid.module.css';
import { ISSUE_CATEGORIES } from '../util';

import info from './images/info.svg';
import disruption from './images/disruption.svg';
import bagDamage from './images/bagDamage.svg';
import feedback from './images/feedback.svg';
import mishandled from './images/mishandled.svg';
import passengerRights from './images/passengerRights.svg';
import refund from './images/refund.svg';
import seat from './images/seat.svg';
import denied from './images/denied.svg';
import voluntaryDenied from './images/voluntaryDenied.svg';
import categoryLine from './images/categoryLine.svg';
import wheel from './images/wheel.png';
import chevBlue from './images/chevBlue.svg';

class IssueGridImg extends React.Component {
    render() {
        if (this.props.tile == ISSUE_CATEGORIES.DISRUPTION_REIMBURSEMENT) {
            return (<img src={disruption} className={styles.img} />);
        }
        else if (this.props.tile == ISSUE_CATEGORIES.BAG_DAMAGE) {
            return (<img src={bagDamage} className={styles.img} />);
        }
        else if (this.props.tile == ISSUE_CATEGORIES.FEEDBACK) {
            return (<img src={feedback} className={styles.img} />);
        }
        else if (this.props.tile == ISSUE_CATEGORIES.LOST_BAG) {
            return (<img src={mishandled} className={styles.img} />);
        }
        else if (this.props.tile == ISSUE_CATEGORIES.PASSENGER_RIGHTS) {
            return (<img src={passengerRights} className={styles.img} />);
        }
        else if (this.props.tile == ISSUE_CATEGORIES.REFUND_REQUEUST) {
            return (<img src={refund} className={styles.img} />);
        }
        else if (this.props.tile == ISSUE_CATEGORIES.BAG_SEAT_WIFI) {
            return (<img src={seat} className={styles.img} />);
        }
        else if (this.props.tile == ISSUE_CATEGORIES.INVOLUNTARY_DENIED) {
            return (<img src={denied} className={styles.img} />);
        }
        else if (this.props.tile == ISSUE_CATEGORIES.VOLUNTARY_DENIED) {
            return (
                <div>
                    <img src={voluntaryDenied} className={styles.img1} />
                    <img src={refund} className={styles.img2} />
                    <img src={categoryLine} className={styles.imgDivide} />
                </div>
            );
        }
        else {
            return (<div />);
        }
    }
}

class IssueGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tileText: JSON.parse(JSON.stringify(Object.keys(ISSUE_CATEGORIES))),
            visibleCards: new Set(),
            searchTerm: "",
            keywords: {
                "delay": [0],
                "claim": [0],
                "cancellation": [0, 4],
                "money": [0, 4],
                "hotel": [0],
                "food": [0],
                "voucher": [0],
                "reimbursement": [0],
                "compensation": [0],
                "refund": [0],
                "payment": [0, 4],
                "get": [0],
                "eu": [4],
                "261": [4],
                "passenger": [4],
                "rights": [4],
                "law": [4],
                "delay": [4],
                "file": [4],
                "uk": [4],
                "airhelp": [4]
            }
        };

        this.updateSearch = this.updateSearch.bind(this);
    }

    updateSearch(term) {
        let keywords = term.split(" ");
        let visibleCards = new Set();
        for (let i = 0; i < keywords.length; i++) {
            if (this.state.keywords[keywords[i].toLowerCase()] != undefined) {
                for (let j = 0; j < this.state.keywords[keywords[i].toLowerCase()].length; j++) {
                    visibleCards.add(this.state.keywords[keywords[i].toLowerCase()][j]);
                }
            }
        }
        this.setState({visibleCards: visibleCards, searchTerm: term});
    }

    render() {
        if (this.props.searchTerm != this.state.searchTerm) {
            this.updateSearch(this.props.searchTerm);
        }
        if (this.props.searchTerm != "" && this.state.visibleCards.size == 0) {
            return (
                <div id={styles.cantHelpOuter}>
                    <div id={styles.cantHelpDiv}>
                        <img src={wheel} id={styles.cantHelpImg} />
                        <div id={styles.cantHelpText}>We can't help with that yet</div>
                        <div className={styles.button} style={{left: "245px"}} onClick={this.props.clearSearchTerm} >
                            <img src={chevBlue} id={styles.arrowLeft} />
                            <div className={styles.buttonText} style={{right: "20px", textAlign: "right"}}>Clear Search</div>
                        </div>
                        <div className={styles.button} style={{right: "245px"}}>
                            <img src={chevBlue} id={styles.arrowRight} />
                            <div className={styles.buttonText} style={{left: "20px"}}>General Feedback</div>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div id={styles.outer}>
                {
                    this.state.visibleCards.size > 0 ? 
                    Array.from(this.state.visibleCards).map((tileIndex, index) => 
                        <div key={index} className={styles.tile} style={{top: `${Math.floor(index / 3) * 211}px`, left: `${index % 3 * 307}px`}} onClick={() => this.props.selectCategory(ISSUE_CATEGORIES[this.state.tileText[tileIndex]])}>
                            <img src={info} className={styles.info} />
                            <IssueGridImg tile={ISSUE_CATEGORIES[this.state.tileText[tileIndex]]} />
                            <div className={styles.text}>{ISSUE_CATEGORIES[this.state.tileText[tileIndex]]}</div>
                        </div>)
                    :
                    this.state.tileText.map((tile, index) => 
                    <div key={index} className={styles.tile} style={{top: `${Math.floor(index / 3) * 211}px`, left: `${index % 3 * 307}px`}} onClick={() => this.props.selectCategory(ISSUE_CATEGORIES[tile])}>
                        <img src={info} className={styles.info} />
                        <IssueGridImg tile={ISSUE_CATEGORIES[tile]} />
                        <div className={styles.text}>{ISSUE_CATEGORIES[tile]}</div>
                    </div>)
                }
            </div>
        );
    }
}

export default IssueGrid;