import React from 'react';
import styles from './Feedback.module.css';
import globalStyles from '../global.module.css';
import { ISSUE_CATEGORIES, DETAILS_STATES, LOADING_LOCS } from '../util';

import chevWhite from './images/chevWhite.svg';
import chevGrey from './images/chevGrey.svg';

class Feedback extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            issue: ""
        };
    }

    render() {
        // Only show feedback in the feedback workflow 
        if (this.props.data.category != ISSUE_CATEGORIES.FEEDBACK) {
            return (<div />);
        }
        else if (this.props.state < DETAILS_STATES.FEEDBACK && !this.props.visited[DETAILS_STATES.FEEDBACK]) {
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterPreview}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitlePreview}`}>Feedback</div>
                    {
                        LOADING_LOCS.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                    }
                </div>
            );
        }
        else {
            return (
                <div>
                <div className={globalStyles.stepOuter} style={{height: "433px", marginBottom: "-23px"}}>
                    <div className={globalStyles.stepTitle}>Feedback</div>
                    <div id={styles.promptOuter}>
                        <div id={styles.promptText}>Let us know what we did well or what we could improve (max 1500 characters)<span style={{color: "#FF8062"}}>*</span></div>
                    </div>
                    <div id={styles.inputOuter}>
                        <textarea id={styles.input} value={this.state.issue} onChange={(e) => this.setState({issue: e.target.value})} placeholder="I had the following problem on my flight..." />
                    </div>
                </div>
                {
                    this.state.issue != "" ? 
                    <div className={globalStyles.continue} onClick={() => this.props.submitClaim(this.state.issue)}>
                        <div id={styles.selectText} style={{color: "white"}}>Submit Feedback</div>
                        <img src={chevWhite} className={globalStyles.continueArrow} />
                    </div>
                    :
                    <div className={globalStyles.continueDisabled}>
                        <div id={styles.selectText} style={{color: "#A7A7A7"}}>Select to Continue</div>
                        <img src={chevGrey} className={globalStyles.continueArrow} />
                    </div>
                }
                </div>
            )
        }
    }
}
export default Feedback;