import React from 'react';
import styles from './Confirm.module.css';
import globalStyles from '../global.module.css';
import { DETAILS_STATES, PAYMENT_STATES, LOADING_LOCS, CLAIM_STATUS, ISSUE_CATEGORIES } from '../util';

import plaid from './images/plaid.svg';
import plaidWhite from './images/plaidWhite.svg';
import bank from './images/bank.svg';
import venmo from './images/venmo.svg';
import venmoBlack from './images/venmoBlack.svg';
import ganderAir from './images/ganderair.png';
import breezeAir from './images/breezeLogoSmall.png';
import checkmark from './images/checkmark.svg';
import arrow from './images/arrow.svg';
import chase from './images/chase.svg';
import cancel from './images/cancel.svg';
import chevWhite from './images/chevWhite.svg';

class Confirm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: PAYMENT_STATES.UNDECIDED,
            bankConnected: false,
            details: "",
            details2: ""
        };

        this.selectPayment = this.selectPayment.bind(this);
    }

    selectPayment(index) {
        if (this.state.selected == index) {
            this.setState({selected: PAYMENT_STATES.UNDECIDED});
        }
        else {
            this.setState({selected: index});
        }
    }

    textFieldsFilled() {
        if (document.getElementById("emailAddress") == null || document.getElementById("dateOfBirth") == null) {
            return false;
        }
        return document.getElementById("emailAddress").value != "" && document.getElementById("dateOfBirth").value != "";
    }

    render() {
        if (this.props.data.category == ISSUE_CATEGORIES.FEEDBACK || this.props.data.category == ISSUE_CATEGORIES.LOST_BAG) {
            return (<div />);
        }
        else if (this.props.state == DETAILS_STATES.CONFIRM) {
            let totalAccepted = 0;
            let totalDisputed = 0;
            let listToSearch = this.props.data.claims;
            if (this.props.data.category == ISSUE_CATEGORIES.BAG_DAMAGE) {
                listToSearch = this.props.data.bagClaims;
            }
            for (let i = 0; i < listToSearch.length; i++) {
                if (listToSearch[i].status == CLAIM_STATUS.ACCEPTED) {
                    totalAccepted += listToSearch[i].amount;
                }
                else if (listToSearch[i].status == CLAIM_STATUS.DISPUTED) {
                    totalDisputed += listToSearch[i].amount;
                }
            }
            let totalRoundedUp = Math.ceil(totalAccepted / 50) * 50;
            let totalPoints = totalRoundedUp * 100;
            totalPoints = totalPoints.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            if (totalDisputed > 0) {
                return (
                    <div>
                        <div className={globalStyles.stepOuter} style={{height: "510px", marginBottom: "-23px"}}>
                            <div className={globalStyles.stepTitle}>Confirm</div>
                        <div id={styles.approvedOuter}>
                            <div className={styles.approvedTitle}>${totalAccepted}</div>
                            <div className={styles.approvedSubtitle}>Compensation Approved</div>
                        </div>
                        <div id={styles.disputedOuter}>
                            <div className={styles.approvedTitle}>${totalDisputed}</div>
                            <div className={styles.approvedSubtitle}>Compensation Disputed</div>
                        </div>
                        <div id={styles.disputedText1}>What happens next:</div>
                        <div id={styles.disputedText2}>Someone from our customer care team will carefully review the context behind your claims and come to a <b>final</b> decision regarding disputed compensation. Once they do, you should be able to come back to this system and select your payout method. Your approved compensation is not going anywhere.</div>
                        <div id={styles.undoDisputes}>You may also choose to remove your disputed claim and claim an instant payout by clicking <u><span style={{color: "#3564E8", cursor: "pointer"}} onClick={this.props.removeDisputed}>here.</span></u></div>
                    </div>
                    <div className={globalStyles.continue} onClick={() => this.props.submitClaim(totalAccepted.toFixed(2), this.state.details)}>
                        <div id={styles.selectText} style={{color: "white"}}>Submit For Review</div>
                    </div>
                    </div>
                );
            }
            else if (this.props.data.paymentForm == PAYMENT_STATES.UNDECIDED) {
                return (
                    <div>
                    <div className={globalStyles.stepOuter} style={{height: "510px", marginBottom: "-23px"}}>
                        <div className={globalStyles.stepTitle}>Confirm</div>
                        <div id={styles.approvedBox}>
                            <div id={styles.approvedTitle}>${totalAccepted.toFixed(2)}</div>
                            <div id={styles.approvedSubtitle}>Compensation<br/>Approved</div>
                        </div>
                        <div id={styles.otherOptionsTitle}>Select Payout Option</div>
                        <div className={styles.otherOptionBox} onClick={() => this.selectPayment(PAYMENT_STATES.AIRLINE_CREDIT)} style={{top: "139px", border: "2px solid #0DDD60"}}>
                            <div id={styles.whiteBox}>
                                <div id={styles.bestValueText}>Best Value</div>
                            </div>
                            {
                                this.state.selected == PAYMENT_STATES.AIRLINE_CREDIT ? 
                                <div className={styles.selected}>
                                    <img src={checkmark} className={styles.selectedImg} />
                                </div>
                                :
                                <div className={styles.selector} />
                            }
                            <img src={breezeAir} className={styles.otherOptionsImgSw} />
                            <div className={styles.otherOptionsTitle}>${totalRoundedUp} Breeze Airlines Credit</div>
                        </div>
                        <div className={styles.otherOptionBox} onClick={() => this.selectPayment(PAYMENT_STATES.AIRLINE_POINTS)} style={{top: "218px"}}>
                            {
                                this.state.selected == PAYMENT_STATES.AIRLINE_POINTS ? 
                                <div className={styles.selected}>
                                    <img src={checkmark} className={styles.selectedImg} />
                                </div>
                                :
                                <div className={styles.selector} />
                            }
                            <img src={breezeAir} className={styles.otherOptionsImgSw} />
                            <div className={styles.otherOptionsTitle}>{totalPoints} Rewards Points</div>
                        </div>
                        <div className={styles.otherOptionBox} onClick={() => this.selectPayment(PAYMENT_STATES.ELECTRONIC_FUNDS)} style={{top: "297px"}}>
                            {
                                this.state.selected == PAYMENT_STATES.ELECTRONIC_FUNDS ? 
                                <div className={styles.selected}>
                                    <img src={checkmark} className={styles.selectedImg} />
                                </div>
                                :
                                <div className={styles.selector} />
                            }
                            <img src={bank} className={styles.otherOptionsImg} />
                            <div className={styles.otherOptionsTitle}>${totalAccepted.toFixed(2)} Electronic Funds Transfer</div>
                            <div id={styles.poweredBy}>Powered By</div>
                            <img src={plaid} id={styles.plaid} />
                        </div>
                        <div className={styles.otherOptionBox} onClick={() => this.selectPayment(PAYMENT_STATES.VENMO)} style={{top: "376px"}}>
                            {
                                this.state.selected == PAYMENT_STATES.VENMO ? 
                                <div className={styles.selected}>
                                    <img src={checkmark} className={styles.selectedImg} />
                                </div>
                                :
                                <div className={styles.selector} />
                            }
                            <img src={venmoBlack} className={styles.otherOptionsImg} />
                            <div className={styles.otherOptionsTitle}>${totalAccepted.toFixed(2)} Venmo Payout</div>
                        </div>
                    </div>
                    {
                        this.state.selected != PAYMENT_STATES.UNDECIDED ? 
                        <div className={globalStyles.continue} onClick={() => this.props.updatePaymentForm(this.state.selected)}>
                            <div id={styles.selectText} style={{color: "white"}}>Next Step</div>
                            <img src={chevWhite} className={globalStyles.continueArrow} style={{transform: "rotate(-90deg)"}}/>
                        </div>
                        :
                        <div className={globalStyles.continueDisabled}>
                            <div id={styles.selectText} style={{color: "#A7A7A7"}}>Select a payout method</div>
                        </div>
                    }
                    </div>
                )
            }
            let flightsString = "";
            if (this.props.data.flight1 && this.props.data.flight2) {
                flightsString = "WN 356 & WN 357";
            }
            else if (this.props.data.flight1) {
                flightsString = "WN 356";
            }
            else if (this.props.data.flight2) {
                flightsString = "WN 357";
            }
            if (this.props.data.paymentForm == PAYMENT_STATES.AIRLINE_CREDIT) {
                return (
                    <div>
                    <div className={globalStyles.stepOuter} style={{height: "510px", marginBottom: "-23px"}}>
                        <div className={globalStyles.stepTitle}>Confirm</div>
                        <div id={styles.approvedBox} style={{backgroundColor: "#3564E8"}}>
                            <img src={breezeAir} id={styles.headerImgSw} />
                            <div id={styles.approvedTitle1}>${totalRoundedUp}<br/>Credit</div>
                            <div id={styles.termsFooter}><u>Terms & Conditions</u></div>
                        </div>
                        <div id={styles.changeTrip} onClick={() => this.props.updatePaymentForm(PAYMENT_STATES.UNDECIDED)}>
                            <div id={styles.changeTripText}>Payout Options</div>
                            <img src={arrow} id={styles.changeTripImg} />
                        </div>
                        <div id={styles.otherOptionsTitle1}>Provide Payout Details</div>
                        <div className={styles.textInputOuter} style={{top: "157px"}}>
                            <input type="text" className={globalStyles.inputField} id="emailAddress" placeholder='e.x ABCGEF' onInput={(e) => this.setState({details: e.target.value})} />
                            <div className={globalStyles.inputFieldTitle}>Email Address</div>
                        </div>
                        <div className={styles.textInputOuter} style={{top: "252px"}}>
                            <input type="text" className={globalStyles.inputField} id="dateOfBirth" placeholder='1/1/1990' onInput={(e) => this.setState({details2: e.target.value})} />
                            <div className={globalStyles.inputFieldTitle}>Date of Birth</div>
                        </div>
                        <div id={styles.footerTitle}>Waiving Rights to Future Compensation for {flightsString}</div>
                        <div id={styles.footerSubtitle}>After clicking “Get Credit”, you may not request further compensation<br/>for these flights from Breeze Airlines and you waive your right to<br/>compensation under Department of Transportation Rules</div>
                    </div>
                    {
                        this.state.details != "" && this.state.details2 != "" ? 
                        <div className={globalStyles.continue} onClick={() => this.props.submitClaim(totalRoundedUp, this.state.details)}>
                            <div id={styles.selectText} style={{color: "white"}}>Get Credit</div>
                        </div>
                        :
                        <div className={globalStyles.continueDisabled}>
                            <div id={styles.selectText} style={{color: "#A7A7A7"}}>Get Credit</div>
                        </div>
                    }
                    </div>
                );
            }
            if (this.props.data.paymentForm == PAYMENT_STATES.AIRLINE_POINTS) {
                return (
                    <div>
                    <div className={globalStyles.stepOuter} style={{height: "510px", marginBottom: "-23px"}}>
                        <div className={globalStyles.stepTitle}>Confirm</div>
                        <div id={styles.approvedBox}>
                            <img src={breezeAir} id={styles.headerImgSw} style={{top: "30px"}} />
                            <div id={styles.approvedTitle1} style={{color: "black", fontWeight: "600", top: "180px"}}>{totalPoints}<br/>Points</div>
                            <div id={styles.termsFooter} style={{color: "#979797"}}><u>Terms & Conditions</u></div>
                        </div>
                        <div id={styles.changeTrip} onClick={() => this.props.updatePaymentForm(PAYMENT_STATES.UNDECIDED)}>
                            <div id={styles.changeTripText}>Payout Options</div>
                            <img src={arrow} id={styles.changeTripImg} />
                        </div>
                        <div id={styles.otherOptionsTitle1}>Provide Payment Details</div>
                        <div className={styles.textInputOuter} style={{top: "160px"}}>
                            <input type="text" className={globalStyles.inputField} id="rapidRewards" placeholder='John-Doe' onInput={(e) => this.setState({details: e.target.value, details2: e.target.value})} />
                            <div className={globalStyles.inputFieldTitle}>RapidRewards Account #</div>
                        </div>
                        <div id={styles.newAccountText}>Don't have an account? Join now: </div>
                        <div id={styles.backToSouthwest}>
                            <div id={styles.backToSouthwestText}>Back To flybreeze.com</div>
                        </div>
                        <div id={styles.footerTitle}>Waiving Rights to Future Compensation for {flightsString}</div>
                        <div id={styles.footerSubtitle}>After clicking “Get Credit”, you may not request further compensation<br/>for these flights from Breeze Airlines and you waive your right to<br/>compensation under Department of Transportation Rules</div>
                    </div>
                    {
                        this.state.details != "" && this.state.details2 != "" ? 
                        <div className={globalStyles.continue} onClick={() => this.props.submitClaim(totalPoints, this.state.details)}>
                            <div id={styles.selectText} style={{color: "white"}}>Get Miles</div>
                        </div>
                        :
                        <div className={globalStyles.continueDisabled}>
                            <div id={styles.selectText} style={{color: "#A7A7A7"}}>Get Miles</div>
                        </div>
                    }
                    </div>
                );
            }
            if (this.props.data.paymentForm == PAYMENT_STATES.ELECTRONIC_FUNDS) {
                return (
                    <div>
                    <div className={globalStyles.stepOuter} style={{height: "510px", marginBottom: "-23px"}}>
                        <div className={globalStyles.stepTitle}>Confirm</div>
                        <div id={styles.approvedBox}>
                            <img src={bank} id={styles.headerImgBank} />
                            <div id={styles.approvedTitle} style={{color: "black", top: "190px"}}>${totalAccepted.toFixed(2)}</div>
                            <div id={styles.termsFooter} style={{color: "#979797"}}><u>Terms & Conditions</u></div>
                        </div>
                        <div id={styles.changeTrip} onClick={() => this.props.updatePaymentForm(PAYMENT_STATES.UNDECIDED)}>
                            <div id={styles.changeTripText}>Payout Options</div>
                            <img src={arrow} id={styles.changeTripImg} />
                        </div>
                        <div id={styles.otherOptionsTitle1}>Provide Payment Details</div>
                        {
                            this.state.bankConnected ? 
                            <div>
                                <div id={styles.connectBankTitle} style={{top: "135px"}}>Connect Your Bank Account:</div>
                                <div id={styles.selectedBankOuter}>
                                    <img src={chase} id={styles.chaseLogo} />
                                    <div id={styles.selectedBankTitle}>CHASE BANK</div>
                                    <div id={styles.selectedBankSubtitle}>Select Checking (..8321)</div> 
                                    <div className={styles.cancelOuter} onClick={() => this.setState({bankConnected: false})}>
                                        <img src={cancel} className={styles.cancelImg} />
                                    </div>
                                </div>
                                <div className={styles.textInputOuter} style={{top: "263px"}}>
                                    <input type="text" className={globalStyles.inputField} id="bankDateOfBirth" placeholder='1/1/90' onInput={(e) => this.setState({details: e.target.value, details2: e.target.value})} />
                                    <div className={globalStyles.inputFieldTitle}>{this.props.baseData.passengers[0]} Date of Birth</div>
                                </div>
                                <div id={styles.footerTitle}>Waiving Rights to Future Compensation for {flightsString}</div>
                                <div id={styles.footerSubtitle}>After clicking “Get Credit”, you may not request further compensation<br/>for these flights from Breeze Airlines and you waive your right to<br/>compensation under Department of Transportation Rules</div>
                            </div>
                            :
                            <div>
                                <div id={styles.connectBankTitle}>Connect Your Bank Account:</div>
                                <div id={styles.plaidOuter} onClick={() => this.setState({bankConnected: true})}>
                                    <div id={styles.plaidText}>Connect with</div>
                                    <img src={plaidWhite} id={styles.plaidImg} />
                                </div>
                            </div>
                        }
                    </div>
                    {
                        this.state.details && this.state.details2 ? 
                        <div className={globalStyles.continue} onClick={() => this.props.submitClaim(totalAccepted.toFixed(2), this.state.details)}>
                            <div id={styles.selectText} style={{color: "white"}}>Get Payout</div>
                        </div>
                        :
                        <div className={globalStyles.continueDisabled}>
                            <div id={styles.selectText} style={{color: "#A7A7A7"}}>Get Payout</div>
                        </div>
                    }
                    </div>
                );
            }
            if (this.props.data.paymentForm == PAYMENT_STATES.VENMO) {
                return (
                    <div>
                    <div className={globalStyles.stepOuter} style={{height: "510px", marginBottom: "-23px"}}>
                        <div className={globalStyles.stepTitle}>Confirm</div>
                        <div id={styles.approvedBox}>
                            <img src={venmo} id={styles.headerImgBank} />
                            <div id={styles.approvedTitle} style={{color: "black", top: "190px"}}>${totalAccepted.toFixed(2)}</div>
                            <div id={styles.termsFooter} style={{color: "#979797"}}><u>Terms & Conditions</u></div>
                        </div>
                        <div id={styles.changeTrip} onClick={() => this.props.updatePaymentForm(PAYMENT_STATES.UNDECIDED)}>
                            <div id={styles.changeTripText}>Payout Options</div>
                            <img src={arrow} id={styles.changeTripImg} />
                        </div>
                        <div id={styles.otherOptionsTitle1}>Provide Payment Details</div>
                        <div className={styles.textInputOuter} style={{top: "157px"}}>
                            <input type="text" className={globalStyles.inputField} id="venmoHandle" placeholder='John-Doe' onInput={(e) => this.setState({details: e.target.value})} />
                            <div className={globalStyles.inputFieldTitle}>Venmo Handle</div>
                        </div>
                        <div className={styles.textInputOuter} style={{top: "250px"}}>
                            <input type="text" className={globalStyles.inputField} id="venmoDateOfBirth" placeholder='1/1/1990' onInput={(e) => this.setState({details2: e.target.value})} />
                            <div className={globalStyles.inputFieldTitle}>{this.props.baseData.passengers[0]} Date of Birth</div>
                        </div>
                        <div id={styles.footerTitle}>Waiving Rights to Future Compensation for {flightsString}</div>
                        <div id={styles.footerSubtitle}>After clicking “Get Credit”, you may not request further compensation<br/>for these flights from Breeze Airlines and you waive your right to<br/>compensation under Department of Transportation Rules</div>
                    </div>
                    {
                        this.state.details && this.state.details2 ? 
                        <div className={globalStyles.continue} onClick={() => this.props.submitClaim(totalAccepted.toFixed(2), this.state.details)}>
                            <div id={styles.selectText} style={{color: "white"}}>Get Credit</div>
                        </div>
                        :
                        <div className={globalStyles.continueDisabled}>
                            <div id={styles.selectText} style={{color: "#A7A7A7"}}>Get Credit</div>
                        </div>
                    }
                    </div>
                );
            }
        }
        if (this.props.visited[DETAILS_STATES.CONFIRM]) {
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitleVisited}`}>Confirm</div>
                    <div className={globalStyles.changeTrip} onClick={() => this.props.updateState(DETAILS_STATES.CONFIRRM)}>
                        <div className={globalStyles.changeTripText}>Change Selection</div>
                        <img src={arrow} className={globalStyles.changeTripImg} />
                    </div>
                </div>
            )
        }
        if (this.props.state == DETAILS_STATES.ELIGIBILITY && !this.props.data.containsEligibleFlights) {
            return (<div />);
        }
        return (
            <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterPreview}`}>
                <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitlePreview}`}>Confirm</div>
                {
                    LOADING_LOCS.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                }
            </div>
        );
    }
}

export default Confirm;