import React from 'react';
import styles from './Alerts.module.css';
import globalStyles from '../global.module.css';

import close from '../Form/images/close.png';

export class DeletionAlert extends React.Component {
    render() {
        return (
            <div className={globalStyles.popupBackground} onClick={this.props.close}>
                <div id={styles.alertOuter} onClick={(e) => e.stopPropagation()}>
                    <div id={styles.alertTitle}>Are you sure you want to delete this expense?</div>
                    <div id={styles.alertSubtitle}>You will have to refile it if you would like to claim compensation</div>
                    <div className={styles.cancel} onClick={this.props.close}>Cancel</div>
                    <div className={styles.delete} onClick={this.props.delete}>Delete</div>
                    <div className={globalStyles.closeLarge} onClick={this.props.close}>
                        <img src={close} className={globalStyles.closeLargeImg} />
                    </div>
                </div>
            </div>
        )
    }
}