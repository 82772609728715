import React from 'react';
import styles from './BagIdentification.module.css';
import globalStyles from '../global.module.css';

import { ISSUE_CATEGORIES, DETAILS_STATES, LOADING_LOCS } from '../util';
import angleBracketGrey from "./images/angleBracketGrey.png";
import angleBracketBlack from "./images/angleBracketBlack.png";
import chevGrey from "./images/chevGrey.svg";
import arrowWhite from "./images/arrow_white.png";
import arrowGrey from "./images/arrow_grey.png";

class BagIdentification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            optionIndex: 0,
            bagOuterImg: true
        }
    }

    render() {
        if (this.props.data.category != ISSUE_CATEGORIES.LOST_BAG) {
            return (<div />);
        }
        if (this.props.state < DETAILS_STATES.BAG_IDENTIFICATION && !this.props.visited[DETAILS_STATES.BAG_IDENTIFICATION]) {
            return (
                <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterPreview}`}>
                    <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitlePreview}`}>Identify</div>
                    {
                        LOADING_LOCS.map((locs, index) => <div key={index} className={globalStyles.loading} style={locs} />)
                    }
                </div>
            );
        }
        if (this.props.state == DETAILS_STATES.BAG_IDENTIFICATION) {
            return (
                <div className={globalStyles.stepOuter} style={{height: "548px"}}>
                    <div className={globalStyles.stepTitle}>Identify Your Bag</div>
                    <div id={styles.bagOuter}>
                        {
                            this.state.bagOuterImg ?
                            <div>
                                <img id={styles.bagImg} src={this.props.data.bagMatches[this.state.optionIndex].image} />
                                <img id={styles.leftArrow} src={arrowGrey} />
                                <img id={styles.rightArrow} src={arrowWhite} onClick={() => this.setState({bagOuterImg: false})} style={{cursor: "pointer"}} />
                            </div>
                            :
                            <div>
                                <img id={styles.bagImg} src={this.props.data.bagMatches[this.state.optionIndex].contents} />
                                <img id={styles.leftArrow} src={arrowWhite} onClick={() => this.setState({bagOuterImg: true})} style={{cursor: "pointer", transform: "rotate(180deg)"}} />
                                <img id={styles.rightArrow} src={arrowGrey} style={{transform: "rotate(180deg)"}} />
                            </div>
                        }
                    </div>
                    <div id={styles.bagTitle}>Option {this.state.optionIndex + 1}</div>
                    <div id={styles.bagDetailsOuter}>
                        <div className={styles.bagDetails}><b className={styles.blackText}>Manufacturer: </b>{this.props.data.bagMatches[this.state.optionIndex].manufacturer}</div>
                        <div className={styles.bagDetails}><b className={styles.blackText}>Contents: </b>{this.props.data.bagMatches[this.state.optionIndex].objectList.join(", ")}</div>
                        <div className={styles.bagDetails}><b className={styles.blackText}>Last Seen Location: </b>{this.props.data.bagMatches[this.state.optionIndex].lastSeenLocation}</div>
                        <div className={styles.bagDetails}><b className={styles.blackText}>Last Seen Date: </b>{this.props.data.bagMatches[this.state.optionIndex].lastSeenDate}</div>
                    </div>
                    <div id={styles.indexText}>{this.state.optionIndex + 1} of {this.props.data.bagMatches.length}</div>
                    <div id={styles.backIndex} style={{backgroundColor: this.state.optionIndex == 0 ? "#F5F4F4" : "#D9D9D9", transform: this.state.optionIndex == 0 ? "rotate(0deg)" : "rotate(180deg)", cursor: this.state.optionIndex == 0 ? "default" : "pointer"}} onClick={() => {if (this.state.optionIndex > 0) {this.setState({optionIndex: this.state.optionIndex - 1})}}}>
                        <img src={this.state.optionIndex == 0 ? angleBracketGrey : angleBracketBlack} className={styles.navImage} />
                    </div>
                    <div id={styles.fwdIndex} style={{backgroundColor: this.state.optionIndex == this.props.data.bagMatches.length - 1 ? "#F5F4F4" : "#D9D9D9", transform: this.state.optionIndex == this.props.data.bagMatches.length - 1 ? "rotate(180deg)" : "rotate(0deg)", cursor: this.state.optionIndex == this.props.data.bagMatches.length - 1 ? "default" : "pointer"}} onClick={() => {if (this.state.optionIndex < this.props.data.bagMatches.length - 1) {this.setState({optionIndex: this.state.optionIndex + 1})}}}>
                        <img src={this.state.optionIndex == this.props.data.bagMatches.length - 1 ? angleBracketGrey : angleBracketBlack} className={styles.navImage} />
                    </div>
                    <div id={styles.claimButton} onClick={() => this.props.selectBagMatch(this.state.optionIndex)}>Claim</div>
                    <div id={styles.noneButton} onClick={() => this.props.selectBagMatch(-1)}>None of These</div>
                </div>
            );
        }
        return (
            <div className={`${globalStyles.stepOuter} ${globalStyles.stepOuterVisited}`}>
                <div className={`${globalStyles.stepTitle} ${globalStyles.stepTitleVisited}`}>Bag Identity</div>
                <div className={globalStyles.changeTrip} onClick={() => this.props.updateState(DETAILS_STATES.BAG_IDENTIFICATION)}>
                    <img src={chevGrey} className={globalStyles.changeTripImg} />
                    <div className={globalStyles.changeTripText}>Change Selection</div>
                </div>
            </div>
        );
    }
}
export default BagIdentification;